import {
    testArrayContainsValue,
    testIsArray,
    testIsBoolean,
    testIsNonEmptyString,
    testIsObject,
    testIsString,
    testIsUUID4,
    testObjectHasKey,
} from '../../../lib/test-and-assert/test-base';
import { INPUT_STATUS_API, INPUT_STATUS_UPLOADER, INPUT_TYPES } from './consts';
import { GLOBAL_EVENT_LIMITS, GLOBAL_EVENTS, INPUT_EVENTS, UploaderEvent } from './event';
import { Uploader } from './uploader';

/**
 * @param {RawJobInfo} rawJobInfo
 *
 * @return {boolean}
 */
function testIsRawJobInfo(rawJobInfo) {
    if (!testIsObject(rawJobInfo)) {
        return false;
    }

    if (!testIsUUID4(rawJobInfo.id)) {
        return false;
    }

    if (!testIsNonEmptyString(rawJobInfo.server)) {
        return false;
    }

    if (!testIsNonEmptyString(rawJobInfo.token)) {
        return false;
    }

    if (!testIsArray(rawJobInfo.conversion)) {
        return false;
    }

    return true;
}

/**
 * @param {RawInputInfo} rawInputInfo
 *
 * @return {boolean}
 */
function testIsRawInputInfo(rawInputInfo) {
    if (!testIsObject(rawInputInfo)) {
        return false;
    }

    if (!testIsUUID4(rawInputInfo.id)) {
        return false;
    }

    if (!testIsString(rawInputInfo.source)) {
        return false;
    }

    if (!testIsNonEmptyString(rawInputInfo.type)) {
        return false;
    }

    if (!testIsObject(rawInputInfo.metadata)) {
        return false;
    }

    if (!testIsString(rawInputInfo.status) || !testIsInputStatusApi(rawInputInfo.status)) {
        return false;
    }

    return true;
}

/**
 * @param {UploadedFileInfo} uploadedFileInfo
 *
 * @return {boolean}
 */
function testIsUploadedFileInfoObject(uploadedFileInfo) {
    if (!testIsObject(uploadedFileInfo)) {
        return false;
    }

    if (!testObjectHasKey(uploadedFileInfo, 'id')) {
        return false;
    }

    if (!testIsUUID4(uploadedFileInfo.id.job)) {
        return false;
    }

    if (!testIsUUID4(uploadedFileInfo.id.input)) {
        return false;
    }

    if (!testIsObject(uploadedFileInfo.metadata)) {
        return false;
    }

    if (!testIsBoolean(uploadedFileInfo.completed)) {
        return false;
    }

    return true;
}

/**
 * @param {String} status
 *
 * @return {boolean}
 */
function testIsInputStatusUploader(status) {
    if (!testIsNonEmptyString(status)) {
        return false;
    }

    return testArrayContainsValue(INPUT_STATUS_UPLOADER, status);
}

/**
 * @param {String} status
 *
 * @return {boolean}
 */
function testIsInputStatusApi(status) {
    if (!testIsNonEmptyString(status)) {
        return false;
    }

    return testArrayContainsValue(INPUT_STATUS_API, status);
}

/**
 * @param {String} type
 *
 * @return {boolean}
 */
function testIsInputType(type) {
    if (!testIsNonEmptyString(type)) {
        return false;
    }

    return testArrayContainsValue(INPUT_TYPES, type);
}

/**
 * @param {String} event
 *
 * @return {boolean}
 */
function testIsGlobalEvent(event) {
    if (!testIsNonEmptyString(event)) {
        return false;
    }

    return testArrayContainsValue(GLOBAL_EVENTS, event);
}

/**
 * @param {String} event
 *
 * @return {boolean}
 */
function testIsInputEvent(event) {
    if (!testIsNonEmptyString(event)) {
        return false;
    }

    return testArrayContainsValue(INPUT_EVENTS, event);
}

/**
 * @param {Object} config
 *
 * @return {boolean}
 */
function testIsFileUploadConfig(config) {
    if (!testIsObject(config)) {
        return false;
    }

    return testObjectHasKey(config, 'fileUploadInputId') && testObjectHasKey(config, 'maxFileSize');
}

/**
 * @param {UploaderEvent} uploaderEvent
 *
 * @returns {boolean}
 */
function testIsUploaderEvent(uploaderEvent) {
    return uploaderEvent instanceof UploaderEvent;
}

/**
 * @param {UploaderEvent} uploaderEvent
 *
 * @return {boolean}
 */
function testIsGlobalUploaderLimitEvent(uploaderEvent) {
    return testIsUploaderEvent(uploaderEvent) && GLOBAL_EVENT_LIMITS.includes(uploaderEvent.event);
}

export { testIsRawJobInfo };
export { testIsRawInputInfo };
export { testIsUploadedFileInfoObject };
export { testIsInputStatusUploader };
export { testIsInputStatusApi };
export { testIsInputEvent };
export { testIsInputType };
export { testIsGlobalEvent };
export { testIsFileUploadConfig };
export { testIsUploaderEvent };
export { testIsGlobalUploaderLimitEvent };
