/**
 * Not all browser support window.location.origin
 *
 * @return {string}
 */
var getLocationOrigin = function () {
    var location = window.location;

    if (location.origin) {
        return location.origin;
    }

    return location.protocol + '//' + location.hostname + (location.port && ':' + location.port);
};

export { getLocationOrigin };
