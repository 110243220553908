import { globalLogger } from './global-logger';
import { testIsObject } from '../../lib/test-and-assert/test-base';

/**
 * This function is used to decide if we need to show the password input elements to the user
 *
 * @param {InputMetadata} metadata
 *
 * @return {boolean}
 */
var isPasswordMissing = function (metadata) {
    if (!testIsObject(metadata)) {
        return false;
    }

    var hasPasswordProtectedField = metadata.hasOwnProperty('password_protected');
    var hasPasswordValidField = metadata.hasOwnProperty('password_valid');

    var hasUnsupportedEncryptionField = metadata.hasOwnProperty('unsupported_encryption');

    var hasPdfUserPasswordField = metadata.hasOwnProperty('pdf_has_user_password');
    var hasPdfOwnerPasswordField = metadata.hasOwnProperty('pdf_has_owner_password');
    var hasPdfPasswordValidField = metadata.hasOwnProperty('pdf_password_valid');

    // If both of the main password fields are missing then we return false because we don't
    // know the state of the input password
    if (!hasPasswordValidField && !hasPasswordProtectedField) {
        return false;
    }

    // If password_protected is false then there is no missing password
    if (hasPasswordProtectedField && metadata.password_protected === false) {
        // Just a sanity check
        if (hasPdfUserPasswordField && metadata.pdf_has_user_password === true) {
            globalLogger.error('password helper info', 'password fields broken: ' + JSON.stringify(metadata));
        }

        // Just a sanity check
        if (hasPdfOwnerPasswordField && metadata.pdf_has_owner_password === true) {
            globalLogger.error('password helper info', 'password fields broken: ' + JSON.stringify(metadata));
        }

        return false;
    }

    // If the file has some modern weird encryption which we can't decrypt then there is no missing password
    if (hasUnsupportedEncryptionField && metadata.unsupported_encryption === true) {
        return false;
    }

    // If one field is missing then something might be broken. Please investigate!
    if (!hasPasswordValidField || !hasPasswordProtectedField) {
        globalLogger.error('password helper info', 'password fields broken: ' + JSON.stringify(metadata));

        return false;
    }

    // At this point we know that both main password fields exists in the metadata!

    // just a sanity check if the field has the correct type. If this happens in prod then either
    // the browser is old and broken or we have an API/Satcore problem
    if (metadata.password_protected !== true && metadata.password_protected !== false) {
        globalLogger.error(
            'password helper info',
            'password_protected not a boolean value: ' + JSON.stringify(metadata)
        );
    }

    // If 'password_protected' is not true then the input does not need a password
    if (metadata.password_protected !== true) {
        return false;
    }

    // At this point we know that metadata.password_protected is true

    // Check if all the pdf password fields are missing
    var hasNoPdfPasswordFields = !hasPdfOwnerPasswordField && !hasPdfUserPasswordField && !hasPdfPasswordValidField;

    // The input is not a pdf so we can use a simple logic to decide if password is missing
    if (hasNoPdfPasswordFields) {
        var passwordValid = metadata.password_valid === true;

        // If the password is not valid then the input is missing the correct password
        return !passwordValid;
    }

    // At this point we know that at least one of the pdf password fields exists

    // If one of the pdf password fields is missing then something is horribly broken! We send a log and return false
    // because we are not sure about state of the password
    // This should never happen in prod! If it happens: pls fix in DL/CS
    if (!hasPdfOwnerPasswordField || !hasPdfUserPasswordField || !hasPdfPasswordValidField) {
        globalLogger.error('password helper info', 'pdf password fields missing: ' + JSON.stringify(metadata));

        return false;
    }

    // Both password fields should always have the same value. If they have different values then something is
    // horribly broken. We send a log and return false because we are not sure about state of the password.
    // This should never happen in prod! If it happens: pls fix in DL/CS
    if (metadata.password_valid !== metadata.pdf_password_valid) {
        globalLogger.error(
            'password helper info',
            'pdf password valid inconsistent state: ' + JSON.stringify(metadata)
        );

        return false;
    }

    // At this point we know that all pdf password fields are present and in a valid state

    // If the pdf has a user password then we think about the validity of the password
    if (metadata.pdf_has_user_password === true) {
        // If the pdf password is not valid then the input is missing the correct
        return !metadata.pdf_password_valid;
    }

    // If the pdf does not have a user password then we don't show the password input
    return false;
};

export { isPasswordMissing };
