import { assertIsString } from '../../../../lib/test-and-assert/assert-base';
import { assertIsRawInputInfo } from '../assert';

import { INPUT_STATUS_UPLOADER_LOADING, INPUT_STATUS_UPLOADER_SUBMITTING, INPUT_TYPE_INPUT_ID } from '../consts';
import { INPUT_EVENT_LOADING, INPUT_EVENT_SUBMITTING } from '../event';
import { Input } from '../lib/input';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import { FailInputError } from '../uploader';

/**
 * @param {Uploader} uploader
 *
 * @constructor
 */
function AddInputId(uploader) {
    this._uploader = uploader;
}

/**
 * this is used to add an input id
 *
 * @param {string} inputId
 */
AddInputId.prototype.add = function (inputId) {
    assertIsString(inputId);

    /** @type {Uploader} */
    var _uploader = this._uploader;

    var callback = function (localId) {
        // this deferred is used inside the queue. it is used to prevent that inputs are added in parallel
        // instead inputs are added one after the other
        var deferred = $.Deferred();

        var jobId = _uploader.getJob().getId();

        _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_SUBMITTING);
        _uploader.getInput(localId).setName(inputId);

        _uploader.dispatchInputEvent(INPUT_EVENT_SUBMITTING, localId);

        _uploader
            .getApiHelper()
            .addInputId(jobId, inputId)
            /**
             * @param {object}   inputInfo
             */
            .done(function (rawInputInfo) {
                assertIsRawInputInfo(rawInputInfo);

                var inputId = rawInputInfo.id;

                _uploader.getInput(localId).setInputId(inputId);
                _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);
                _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

                _uploader.waitForRemoteInput(localId);

                deferred.resolve();
            })
            .fail(function (apiError) {
                assertIsApiError(apiError);

                var failInputError = new FailInputError(apiError);

                _uploader.failInput(localId, failInputError);

                deferred.resolve();
            });

        return deferred;
    };

    var input = new Input(INPUT_TYPE_INPUT_ID);

    input.setCallback(callback).setName(inputId);

    this._uploader._queueInput(input);
};

export { AddInputId };
