import { satGlobals } from '../sat/helper/sat-globals';
import { isMobile } from '../../lib-sat/misc/browser-detector';
import { globalLogger } from './global-logger';

let satConnectionType = 'unknown';
if (navigator.connection && navigator.connection.effectiveType) {
    if (navigator.connection.effectiveType === '4g') {
        satConnectionType = 'fast';
    } else {
        satConnectionType = 'slow';
    }
}

let mobile = isMobile();

function sendToAnalytics(metric, value, data) {
    try {
        let url = satGlobals.getApiUrl('api/z');

        let anylticsData = {
            message: metric.toLowerCase(),
            value: value,
            origin: [location.pathname].join(''),
            connection_type: satConnectionType,
            mobile: mobile,
        };

        let bodyData = { ...anylticsData, ...data };

        let body = JSON.stringify({
            data: bodyData,
        });

        let headers = {
            type: 'text/plain',
        };
        let blob = new Blob([body], headers);

        if (navigator.sendBeacon && navigator.sendBeacon(url, blob)) {
            return;
        }

        fetch(url, { body, method: 'POST', keepalive: true });
    } catch (e) {
        globalLogger.log('exception while sending analytics', e);
    }
}

export { sendToAnalytics };
