/**
 * @constructor
 */
function DynamicChunkHandler() {
    this._baseChunkSize = 1000000;
    this._minChunkSize = 100000;
    this._maxChunkSize = 100000000;
    this._speedModifier = 3;
    this._maxSpeedModifier = 5;
    // these 3 are just for logging the last set values
    this._currentChunkSize = -1;
    this._currentBitRate = -1;
    this._currentSpeedModifier = -1;
}

DynamicChunkHandler.prototype.increaseChunkSpeed = function () {
    this._limitSpeed(++this._speedModifier);
};

DynamicChunkHandler.prototype.resetChunkSpeed = function () {
    this._speedModifier = 1;
};

/**
 * @param {JqueryFileUploadData} fileData
 *
 * @return {number}
 */
DynamicChunkHandler.prototype.getDynamicChunkSize = function (fileData) {
    this._currentChunkSize = this._baseChunkSize;
    this._currentBitRate = -1;
    this._currentSpeedModifier = -1;

    try {
        var progress = fileData.progress();

        // This handles the first chunk
        if (progress.loaded < this._baseChunkSize) {
            // The first chunk should use a large-ish chunk size to get a reliable
            // upload bitrate (which is important to calculate the size of the next chunk)
            var firstChunkSize = this._baseChunkSize;

            // If there has been an error uploading the first chunk then the size of the first
            // chunk is reduced. This might help user on really slow connections
            if (fileData.pgRetryHandler.getCurrentRetry() > 0) {
                firstChunkSize = this._minChunkSize;
            }

            this._currentChunkSize = firstChunkSize;

            return firstChunkSize;
        }

        // This is the case where the previous chunk upload failed
        if (this._speedModifier === 1) {
            this._currentChunkSize = this._minChunkSize;

            return this._minChunkSize;
        }

        this._currentBitRate = parseInt(progress.bitrate);
        this._currentSpeedModifier = this._speedModifier;

        // This is the case where the previous chunk upload was successful
        var chunkSize = this._currentBitRate * this._speedModifier;

        this._currentChunkSize = this._limitChunk(chunkSize);
    } catch (e) {}

    return this._currentChunkSize;
};

/**
 * @param {Number} maxChunkSize
 */
DynamicChunkHandler.prototype.setmaxChunkSize = function (maxChunkSize) {
    this._maxChunkSize = maxChunkSize;
};

/**
 * @return {number}
 */
DynamicChunkHandler.prototype.getCurrentChunkSize = function () {
    return this._currentChunkSize;
};

/**
 * @return {number}
 */
DynamicChunkHandler.prototype.getCurrentBitRate = function () {
    return this._currentBitRate;
};

/**
 * @return {number}
 */
DynamicChunkHandler.prototype.getCurrentSpeedModifier = function () {
    return this._currentSpeedModifier;
};

/**
 * @param {number} chunkSize
 *
 * @return {number}
 */
DynamicChunkHandler.prototype._limitChunk = function (chunkSize) {
    return Math.max(this._minChunkSize, Math.min(chunkSize, this._maxChunkSize));
};

/**
 * @param {number} speed
 */
DynamicChunkHandler.prototype._limitSpeed = function (speed) {
    this._speedModifier = Math.max(1, Math.min(speed, this._maxSpeedModifier));
};

export { DynamicChunkHandler };
