import { testArrayContainsValue, testIsNonEmptyString } from './test-base';

import { API_ERRORS } from '../../lib-sat/api/consts';

/**
 * @param {String} apiError
 *
 * @return {boolean}
 */
var testIsApiError = function (apiError) {
    if (!testIsNonEmptyString(apiError)) {
        return false;
    }

    return testArrayContainsValue(API_ERRORS, apiError);
};

export { testIsApiError };
