import { translate } from '../../qaamgo/helper/translate';

var qgSetupBookmarkButtons = function () {
    $(document).on('click', '.bookmark-btn', function (e) {
        e.preventDefault();

        if (window.sidebar && window.sidebar.addPanel) {
            // Firefox <23
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && 'AddFavorite' in window.external) {
            // Internet Explorer
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) {
            // Opera <15
            $(this).attr('rel', 'sidebar').attr('title', document.title).attr('href', window.location.href);

            return true;
        } else {
            var alertText = translate('You can add this page to your bookmarks by pressing %s + D on your keyboard.');

            alertText = alertText.replace(
                '%s',
                navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ? 'Command/Cmd' : 'CTRL'
            );
            alert(alertText);
        }

        return false;
    });
};

qgSetupBookmarkButtons();
