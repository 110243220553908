import { assertIsRawInputInfo } from '../assert';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import { testIsObject, testObjectHasKey } from '../../../../lib/test-and-assert/test-base';

import { globalLogger } from '../../../../qaamgo/helper/global-logger';

import { Input } from '../lib/input';
import { INPUT_STATUS_UPLOADER_LOADING, INPUT_STATUS_UPLOADER_SUBMITTING, INPUT_TYPE_DROPBOX } from '../consts';
import { GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE, INPUT_EVENT_LOADING, INPUT_EVENT_SUBMITTING } from '../event';
import { API_ERROR_LIMITS_INPUT_TOO_LARGE } from '../../../api/consts';
import { FailInputError } from '../uploader';
import { assertIsFunction } from '../../../../lib/test-and-assert/assert-base';

/**
 * @constructor
 */
function DropboxConfig() {
    this.maxFileSize = 8589934592;
}

export { DropboxConfig };

function addScript(callback) {
    assertIsFunction(callback);

    (function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.setAttribute('data-app-key', window.pageConfig.dropboxKey);
        s.src = 'https://www.dropbox.com/static/api/2/dropins.js';
        s.id = 'dropboxjs';
        s.onload = callback;
        let x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    })();
}

/**
 * @param {Uploader} uploader
 * @param {DropboxConfig} config
 *
 * @constructor
 */
class AddDropbox {
    constructor(uploader, config) {
        /** @type {Uploader} */
        this._uploader = uploader;

        /** @type {DropboxConfig} */
        this._config = new DropboxConfig();

        if (testIsObject(config) && testObjectHasKey(config, 'maxFileSize')) {
            this._config = config;
        }

        this._scriptLoaded = false;
    }

    add() {
        if (!window.hasOwnProperty('Dropbox')) {
            globalLogger.log('qgMultiUpload dropbox fail', 'dropbox JS missing');
            alert('Dropbox could not be initialized, please try again later!');

            return;
        }

        /** @type {Uploader} */
        var _uploader = this._uploader;

        /** @type {DropboxConfig} */
        var _config = this._config;

        var _dbCallback = function (files) {
            $.each(files, function (key, value) {
                var url = value.link;
                var size = 0;
                var name = 'Dropbox';

                if (value.hasOwnProperty('bytes') && typeof value.bytes === 'number') {
                    size = value.bytes;
                }

                if (value.hasOwnProperty('name')) {
                    name = value.name;
                }

                helper(url, size, name);
            });
        };

        /**
         * @param {String} url
         * @param {Number} size
         * @param {String} name
         */
        var helper = function (url, size, name) {
            var callback = function (localId) {
                var deferred = $.Deferred();

                _uploader.getInput(localId).setName(name);
                _uploader.getInput(localId).setSize(size);

                // if the sum of the bytes in the job + the size of this input is
                // larger than the max size...
                var totalSize = _uploader.getBytesInJob() + size;

                if (totalSize > _config.maxFileSize) {
                    var failInputError = new FailInputError(API_ERROR_LIMITS_INPUT_TOO_LARGE);

                    failInputError.size = totalSize;

                    globalLogger.log('qgMultiUpload dropbox fail', {
                        info: 'file too large',
                        filename: name,
                        file_size: size,
                        max_file_size: _config.maxFileSize,
                    });

                    _uploader.failInput(localId, failInputError);

                    _uploader.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);

                    return deferred.resolve();
                }

                var jobId = _uploader.getJob().getId();

                _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_SUBMITTING);

                _uploader.dispatchInputEvent(INPUT_EVENT_SUBMITTING, localId);

                _uploader
                    .getApiHelper()
                    .addExternalUrl(jobId, url)
                    /**
                     * @param {object} inputInfo
                     */
                    .done(function (rawInputInfo) {
                        assertIsRawInputInfo(rawInputInfo);

                        var inputId = rawInputInfo.id;

                        _uploader.getInput(localId).setInputId(inputId);
                        _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);

                        _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

                        _uploader.waitForRemoteInput(localId);

                        deferred.resolve();
                    })
                    .fail(function (apiError) {
                        assertIsApiError(apiError);

                        var failInputError = new FailInputError(apiError);

                        _uploader.failInput(localId, failInputError);

                        deferred.resolve();
                    });

                return deferred;
            };

            var input = new Input(INPUT_TYPE_DROPBOX);

            input.setName(name).setSize(size).setCallback(callback);

            _uploader._queueInput(input);
        };

        var options = {
            success: _dbCallback,
            linkType: 'direct',
            multiselect: true,
        };

        Dropbox.choose(options);
    }

    click() {
        const callback = () => {
            this._scriptLoaded = true;

            this.add();
        };

        if (this._scriptLoaded) {
            callback();

            return;
        }

        addScript(callback);
    }
}

export { AddDropbox };
