import { getAssertMessage } from './get-assert-message';
import { testIsApiError } from './test-api';

/**
 * @param {String} apiError
 */
var assertIsApiError = function (apiError) {
    if (!testIsApiError(apiError)) {
        throw new Error(getAssertMessage('not valid apiError'));
    }
};

export { assertIsApiError };
