import { assertIsNonEmptyString, assertIsObject, assertIsTrue } from '../../../../lib/test-and-assert/assert-base';
import {
    testIsEmptyString,
    testIsNonEmptyString,
    testIsNumber,
    testIsObject,
    testObjectHasKey,
} from '../../../../lib/test-and-assert/test-base';

import { globalLogger } from '../../../../qaamgo/helper/global-logger';

import { createUUID4 } from '../../../../qaamgo/helper/uuid';
import { Input } from '../lib/input';
import { isPasswordMissing } from '../../../../qaamgo/helper/input-password';
import { RetryHandler } from './file-upload-retry-handler';
import { logFailInfo, logRetryInfo, logChunk } from './file-upload-log-data';
import { DynamicChunkHandler } from './file-upload-dynamic-chunk-handler';
import { testIsUploadedFileInfoObject } from '../test';
import {
    INPUT_STATUS_UPLOADER_DONE,
    INPUT_STATUS_UPLOADER_LOADING,
    INPUT_STATUS_UPLOADER_PASSWORD_MISSING,
    INPUT_TYPE_FILE_UPLOAD,
    UPLOADSERVER_ERROR_FILE_TOO_LARGE,
    UPLOADSERVER_ERROR_PROBLEM_UPLOADING_FILE,
    UPLOADSERVER_ERROR_SUM_OF_UPLOADS_TOO_LARGE,
} from '../consts';
import {
    GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE,
    INPUT_EVENT_DONE,
    INPUT_EVENT_LOADING,
    INPUT_EVENT_PASSWORD_MISSING,
    INPUT_EVENT_UPLOAD_PROGRESS,
} from '../event';
import { API_ERROR_LIMITS_INPUT_TOO_LARGE, API_ERROR_UNKNOWN } from '../../../api/consts';
import { FailInputError } from '../uploader';
import { assertIsFileUploadConfig } from '../assert';

/**
 * @param {String} url
 * @return {Boolean}
 */
function isUploadFallbackUrl(url) {
    var url_ = url.toLowerCase();

    return url_.indexOf('/upload-redirect/') !== -1;
}

export { isUploadFallbackUrl };

/**
 * @constructor
 */
function FileUploadConfig() {
    this.maxFileSize = 8589934592;
    this.enablePasswords = true;
    this.enableUploadFallback = false;
    this.fileUploadInputId = '#fileUploadInput';
}

export { FileUploadConfig };

/**
 * @param {Uploader} uploader
 * @param {FileUploadConfig} config
 *
 * @constructor
 */
function AddFileUpload(uploader, config) {
    assertIsFileUploadConfig(config);

    /** @type {FileUploadConfig} */
    this._config = config;

    /** @type {Uploader} */
    this._uploader = uploader;

    this._dynamicChunkHandler = new DynamicChunkHandler();

    // Check if the file upload element actually exists
    assertIsNonEmptyString(this._config.fileUploadInputId);
    assertIsTrue($(this._config.fileUploadInputId).length === 1);

    /** @type {AddFileUpload} */
    var _this = this;

    /**
     * @param {JqueryFileUploadData} fileData
     *
     * @return {number}
     */
    function getCurrentChunkSize(fileData) {
        return _this._dynamicChunkHandler.getDynamicChunkSize(fileData);
    }

    var fileUploaderOptions = {
        // the whole list of options can be found here:
        // https://github.com/blueimp/jQuery-File-Upload/wiki/Options

        // singleFileUploads - if true then each file is uploaded via own ajax call. default true
        //                     must be true for uploading to OC download server
        singleFileUploads: true,

        // sequentialUploads - default: false
        //                     Set this option to true to issue all file upload requests in a
        //                     sequential order instead of simultaneous requests.
        sequentialUploads: false,

        // maxChunkSize      - default: undefined
        maxChunkSize: getCurrentChunkSize,

        // autoUpload:       - default: true
        //                     if true then upload start after file is selected
        //                     must be false in our case, we start the downloads in 'fileuploadadd'
        autoUpload: false,

        // The minimum time interval in milliseconds to calculate and trigger progress events
        progressInterval: 500,

        // collection of error messages
        messages: {
            unknownError: 'Unknown error',
            maxNumberOfFiles: 'Maximum number of files exceeded',
            acceptFileTypes: 'File type not allowed',
            maxFileSize: 'File is too large',
            minFileSize: 'File is too small',
            uploadedBytes: 'Uploaded bytes exceed file size',
        },
    };

    var inputElement = $(this._config.fileUploadInputId);
    var fileuploader = inputElement.fileupload(fileUploaderOptions);

    fileuploader.on('fileuploadadd', function (e, fileData) {
        _this._processFileUploadAdd(e, fileData);
    });

    window.addEventListener('paste', (e) => {
        const filesList = e.clipboardData.files;

        if (filesList.length < 1) {
            return;
        }

        Array.from(filesList).forEach((file) => {
            //Files without mimetypes (mostly folders) cannot be uploaded
            //Broken files should automatically have the mimetype octet-stream
            //so this should not be a problem
            if (testIsEmptyString(file.type)) {
                globalLogger.log('uploader - copy paste upload - failed', file);
                return;
            }

            fileuploader.fileupload('add', { files: file });
            globalLogger.log('uploader - copy paste upload - success');
        });
    });

    fileuploader.on('fileuploadsubmit', function (e, fileData) {
        _this._processFileUploadSubmit(e, fileData);
    });

    fileuploader.on('fileuploaddone', function (e, fileData) {
        _this._processFileUploadChunk(e, fileData);
        _this._processFileUploadDone(e, fileData);
    });

    fileuploader.on('fileuploadfail', function (e, fileData) {
        _this._processFileUploadChunk(e, fileData);
        _this._processFileUploadFail(e, fileData);
    });

    fileuploader.on('fileuploadprogress', function (e, fileData) {
        _this._processFileUploadProgress(e, fileData);
    });

    fileuploader.on('fileuploadchunkdone', function (e, fileData) {
        _this._processFileUploadChunk(e, fileData);
        fileData.pgRetryHandler.handleChunkSuccess();
        _this._dynamicChunkHandler.increaseChunkSpeed();
    });
}

/**
 * process 'fileuploadadd' callback from the uploader.
 *
 * it is called when user adds a file. if there is no job id then it creates a new job
 *
 * @param {JQuery.Event}         event
 * @param {JqueryFileUploadData} data
 */
AddFileUpload.prototype._processFileUploadAdd = function (event, data) {
    // in some weird browser 'name' is either empty or not a string
    var filename = 'file';
    try {
        if (testIsNonEmptyString(data.files[0].name)) {
            filename = data.files[0].name;
        }
    } catch (e) {}

    data.pgInitialName = filename;

    // in some weird browser 'size' does not exist or is not a number
    var filesize = 0;
    try {
        if (testIsNumber(data.files[0].size)) {
            filesize = data.files[0].size;
        }
    } catch (e) {}

    data.pgInitialSize = filesize;

    var fileLastModified = 0;
    try {
        if (testIsNumber(data.files[0].lastModified)) {
            fileLastModified = data.files[0].lastModified;
        }
    } catch (e) {}

    if (filename === 'file' || filesize === 0) {
        try {
            var info = 'name: ' + filename + ' size: ' + filesize;

            globalLogger.log('qgMultiUpload fileUpload info incorrect file data', info);
        } catch (e) {}
    }

    /** @type {AddFileUpload} */
    var _this = this;

    /** @type {Uploader} */
    var _uploader = this._uploader;

    /** @type {FileUploadConfig} */
    var _config = this._config;

    var callBack = function (localId) {
        var deferred = $.Deferred();

        _uploader.getInput(localId).setName(filename);
        _uploader.getInput(localId).setSize(filesize);

        // If the sum of the bytes in the job + the size of this input is
        // larger than the max size...
        var totalSize = _uploader.getBytesInJob() + filesize;

        if (totalSize > _uploader._maxFileSize) {
            globalLogger.log('qgMultiUpload fileUpload fail file too large', {
                info: 'file too large',
                filename: filename,
                total_size: totalSize,
                file_size: filesize,
                max_file_size: _config.maxFileSize,
            });

            var failInputError = new FailInputError(API_ERROR_LIMITS_INPUT_TOO_LARGE);

            failInputError.size = totalSize;

            _uploader.failInput(localId, failInputError);

            _uploader.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);

            return deferred.resolve();
        }

        var job = _uploader.getJob();

        data.url = job.getUploadUrl();

        data.pgLocalId = localId;

        _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);
        _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

        // the upload-uuid header must be set here instead of of some beforeSend-ajax-option, otherwise
        // the uploader would generate a new uuid for each uploaded chunk
        data.headers = {
            'X-Oc-Upload-Uuid': createUUID4(),
            'X-Oc-Token': _uploader.getJob().getUploadToken(),
        };

        // this is retry counter for this specific file upload
        data.pgRetryHandler = new RetryHandler();

        var info = 'file name: ' + filename + ' file size: ' + filesize + ' file last modified: ' + fileLastModified;

        globalLogger.log('qgMultiUpload fileUpload start', {
            info: info,
        });

        data.submit();

        deferred.resolve();

        return deferred;
    };

    var input = new Input(INPUT_TYPE_FILE_UPLOAD);

    input.setCallback(callBack).setSize(filesize).setName(filename).setUploadData(data); // this is used for canceling the upload

    _uploader._queueInput(input);
};

/**
 * process 'fileuploadsubmit' callback from the uploader.
 *
 * it is called before the file starts uploading. If it returns false then the upload is canceled
 *
 * @param {JQuery.Event}         event
 * @param {JqueryFileUploadData} data
 */
AddFileUpload.prototype._processFileUploadSubmit = function (event, data) {
    return true;
};

/**
 * process 'fileuploaddone' callback from the uploader.
 *
 * It is called when the upload was successful
 *
 * @param {JQuery.Event}         event
 * @param {JqueryFileUploadData} data
 * @param {UploadedFileInfo} data.result
 */
AddFileUpload.prototype._processFileUploadDone = function (event, data) {
    // This should not happen but it happens from time to time in old browser, especially
    // for some old Firefox below version ~24
    if (typeof data.result === 'string') {
        globalLogger.log('qgMultiUpload fileUpload info', {
            info: 'sever response was not parsed by browser',
            server_response: JSON.stringify(data.result),
        });

        try {
            data.result = JSON.parse(data.result);
        } catch (e) {
            globalLogger.log('qgMultiUpload fileUpload fail', {
                info: 'server response could not be parsed as JSON',
                server_response: JSON.stringify(data.result),
            });
        }
    }

    /** @type {UploadedFileInfo} */
    var uploadedFileInfo = data.result;

    if (!testIsUploadedFileInfoObject(uploadedFileInfo)) {
        globalLogger.log('qgMultiUpload fileUpload fail - invalid response', {
            info: 'response: ' + JSON.stringify(uploadedFileInfo),
        });

        this._uploader.failInput(data.pgLocalId);

        return;
    }

    // In some rare cases there are not-completed-uploads (T4025)
    if (uploadedFileInfo.completed !== true) {
        globalLogger.log('qgMultiUpload fileUpload fail - not completed', {
            info: 'response: ' + JSON.stringify(uploadedFileInfo),
        });

        this._uploader.failInput(data.pgLocalId);

        return;
    }

    var localId = data.pgLocalId;
    var inputId = uploadedFileInfo.id.input;

    this._uploader.getInput(localId).setInputId(inputId);

    // these were already set earlier but we set them again just to be sure
    var filename = data.files[0].name;
    var filesize = data.files[0].size;

    this._uploader.getInput(localId).setName(filename);
    this._uploader.getInput(localId).setSize(filesize);

    if (testIsObject(uploadedFileInfo.metadata)) {
        this._uploader.getInput(localId).setMetadata(uploadedFileInfo.metadata);
    }

    var passwordMissing = isPasswordMissing(uploadedFileInfo.metadata);

    if (this._config.enablePasswords && passwordMissing) {
        this._uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_PASSWORD_MISSING);
        this._uploader.dispatchInputEvent(INPUT_EVENT_PASSWORD_MISSING, localId);
    } else {
        this._uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_DONE);
        this._uploader.dispatchInputEvent(INPUT_EVENT_DONE, localId);
    }

    if (data.pgRetryHandler.getFailedChunks() > 0) {
        globalLogger.log('qgMultiUpload fileUpload info', {
            info: 'upload needed retries',
            input_id: inputId,
            filename: filename,
        });
    }

    var info = 'file name: ' + filename + ' file size: ' + filesize;

    if (isUploadFallbackUrl(data.url)) {
        info += ' use upload fallback: true';

        // write a log just for stats
        globalLogger.log('qgMultiUpload fileUpload with fallback done');
    }

    globalLogger.log('qgMultiUpload fileUpload done', {
        input_id: inputId,
        info: info,
    });
};

/**
 * process 'fileuploadfail' callback from the uploader. it is called when the upload fails for whatever reason
 *
 * in case if internet goes offline during upload then or when there is a connection problem then
 * fileData has these elements (in a modern Chrome, other browser might be different):
 *
 * fileData.errorThrown        ''
 * fileData.textStatus         'error'
 * fileData.jqXHR.status       does not exist
 * fileData.jqXHR.responseText does not exist
 * fileData.jqXHR.responseJSON does not exist
 *
 * if upload is canceled via abort() then:
 *
 * fileData.errorThrown        'abort'
 * fileData.textStatus         'abort'
 * fileData.jqXHR.status       0
 * fileData.jqXHR.statusText   'abort'
 * fileData.jqXHR.responseText does not exist
 * fileData.jqXHR.responseJSON does not exist
 *
 * if the connection is fine but there is something wrong with the upload (e.g. missing headers, ...)
 * then the server gives an answer:
 *
 * fileData.errorThrown        ''
 * fileData.textStatus         'error'
 * fileData.jqXHR.status       contains the http status code
 * fileData.jqXHR.responseText contains the server response as a string
 * fileData.jqXHR.responseJSON contains the server response as a object
 *
 * the responseText might look like this: "{"job_id":"123...","error":"The file could not be uploaded","error_code":99}"
 *
 * @param {JQuery.Event}         event
 * @param {JqueryFileUploadData} data
 */
AddFileUpload.prototype._processFileUploadFail = function (event, data) {
    data.pgCurrentBitrate = this._dynamicChunkHandler.getCurrentBitRate();
    data.pgCurrentChunkSize = this._dynamicChunkHandler.getCurrentChunkSize();
    data.pgCurrentSpeedModifier = this._dynamicChunkHandler.getCurrentSpeedModifier();

    this._dynamicChunkHandler.resetChunkSpeed();

    data.pgRetryHandler.handleChunkFail();

    // TODO: The handling of canceled uploads is currently handled in the UI
    //       it needs to be moved here so that it works even without the UI
    if (data.errorThrown === 'abort') {
        globalLogger.log('qgMultiUpload fileUpload abort', {
            ajax_status: data.textStatus,
            ajax_error: data.errorThrown,
        });

        return;
    }

    var hasJsonResponse = false;

    try {
        hasJsonResponse = typeof data.jqXHR.responseJSON === 'object';
    } catch (e) {}

    var uploadServerErrorCode = null;

    try {
        uploadServerErrorCode = data.jqXHR.responseJSON.error_code;
    } catch (e) {}

    // in some (rare) cases the upload server might answer with:
    // {"error":"Problem uploading file","error_code":6}
    // in such cases the upload is retried
    var problemUploadingFile = uploadServerErrorCode === UPLOADSERVER_ERROR_PROBLEM_UPLOADING_FILE;

    // if there was a) no response or b) a specific response about fileupload problem then we should do a retry
    var doRetry = !hasJsonResponse || problemUploadingFile;

    var retriesAvailable = data.pgRetryHandler.isAllowedToRetry();

    var job = this._uploader.getJob();

    // there was most likely a connection problem so we retry the chunk
    if (doRetry && retriesAvailable) {
        function isOC() {
            try {
                const host = window.location.host.toLowerCase();
                const hostname = window.location.hostname.toLowerCase();

                return host.includes('.online-convert.') || hostname.includes('.online-convert.');
            } catch (e) {
                // do nothing
            }

            return window.location.href.includes('.online-convert.');
        }

        // If no data has been uploaded and it failed a few times then we upload
        // through the main page (if the upload fallback is enabled)
        var switchToFallback =
            data.uploadedBytes === 0 &&
            data.pgRetryHandler.getCurrentRetry() === 3;

        if (switchToFallback) {
            globalLogger.log('qgMultiUpload fileUpload switch to upload fallback');

            data.url = job.getFallbackUploadUrl();

            // set max chunk size to 2 mb when uploading through the redirect
            this._dynamicChunkHandler.setmaxChunkSize(2000000);
        }

        logRetryInfo(data);

        function retry() {
            data.submit();
        }

        window.setTimeout(retry, data.pgRetryHandler.getTimeOutForNextChunk_ms());

        return;
    }

    logFailInfo(data);

    var failInputError = new FailInputError(API_ERROR_UNKNOWN);

    // note: this should not happens because too large files are rejected by
    // the upload queue. It only might happen for broken browser which report a
    // file size of 0 bytes. Then it slips throught he inital check and then
    // the uplaod is rejected by the upload server
    if (
        uploadServerErrorCode === UPLOADSERVER_ERROR_FILE_TOO_LARGE ||
        uploadServerErrorCode === UPLOADSERVER_ERROR_SUM_OF_UPLOADS_TOO_LARGE
    ) {
        failInputError.error = API_ERROR_LIMITS_INPUT_TOO_LARGE;
    }

    this._uploader.failInput(data.pgLocalId, failInputError);
};

/**
 * process 'fileuploadprogress' callback from the uploader.
 *
 * it is called for each file whenever there is new progress information
 *
 * @param {JQuery.Event} event
 * @param {JqueryFileUploadData} data
 */
AddFileUpload.prototype._processFileUploadProgress = function (event, data) {
    /** @type {ProgressData} */
    var progressData = {
        bitrate: data.bitrate,
        bytesLoaded: data.loaded,
        bytesTotal: data.total,
    };

    this._uploader.dispatchInputEvent(INPUT_EVENT_UPLOAD_PROGRESS, data.pgLocalId, progressData);
};

/**
 * process chunk data from the uploader for telemetry.
 *
 *
 * @param {JQuery.Event} event
 * @param {JqueryFileUploadData} data
 */
AddFileUpload.prototype._processFileUploadChunk = function (event, data) {
    try {
        let url = data.url;
        let urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(url);
        let hostParts = urlParts[1].split('.');
        let server = hostParts[0];

        let chunkData = {
            event: event.type,
            bitrate: data.bitrate,
            bytesLoaded: data.loaded,
            bytesTotal: data.total,
            chunkSize: this._dynamicChunkHandler.getCurrentChunkSize(),
            status: data.jqXHR.status,
            server: server,
        };

        logChunk(chunkData);
    } catch (e) {
        // do nothing
    }
};

AddFileUpload.prototype.click = function () {
    const id = this._config.fileUploadInputId;

    const $button = $(id);

    if ($button.length !== 1) {
        return;
    }

    if ($button.hasClass('disabled')) {
        return;
    }

    $button.click();
};

export { AddFileUpload };
