import {
    assertArrayHasIndex,
    assertIsArray,
    assertIsFalse,
    assertIsFunction,
    assertIsNumber,
} from '../../../../lib/test-and-assert/assert-base';

import { Input } from './input.js';
import {
    INPUT_STATUS_UPLOADER_PASSWORD_MISSING,
    INPUT_STATUS_UPLOADER_SETTING_PASSWORD,
    INPUT_TYPE_EXTERNAL_URL,
} from '../consts';

var DEFAULT_MAX_NUMBER_OF_INPUTS = 60;

/**
 * This is a universal function to counts inputs. If the passed function
 * returns true for a given input then the input is counted
 *
 * @param inputs
 * @param {function} f
 *
 * @return {number}
 */
var countInputsWithFunction = function (inputs, f) {
    assertIsArray(inputs);
    assertIsFunction(f);
    var n = 0;
    $.each(inputs, function (id, input) {
        if (f(input)) {
            n++;
        }
    });

    return n;
};

/**
 * @param {UploaderConfig} config
 * @constructor
 */
function InputList(config) {
    /** @type {Input[]} */
    this._inputs = [];

    this._maxNumberOfInputs = DEFAULT_MAX_NUMBER_OF_INPUTS;

    if (config.hasOwnProperty('maxNumberOfInputs')) {
        this._maxNumberOfInputs = config.maxNumberOfInputs;
    }
}

/**
 * Throws an exception if the input does not exist
 *
 * @param localId
 */
InputList.prototype._assertThatInputExists = function (localId) {
    assertArrayHasIndex(this._inputs, localId);
};

/**
 * Returns an input object for a given local id
 *
 * @param {number} localId
 *
 * @returns {Input}
 */
InputList.prototype.getInput = function (localId) {
    assertIsNumber(localId);
    this._assertThatInputExists(localId);

    return this._inputs[localId];
};

/**
 * Returns the first input which is known to the api (a.k.a. non-deleted, non-failed)
 *
 * @returns {Input[]}
 */
InputList.prototype.getInputsInApi = function () {
    if (this._inputs.length === 0) {
        return [];
    }

    /** @type {Input[]} */
    let inputs = [];

    this._inputs.forEach(function (input) {
        if (input.isInApi()) {
            inputs.push(input);
        }
    });

    return inputs;
};

/**
 * Returns an array with all inputs
 *
 * @returns {Input[]}
 */
InputList.prototype.getInputs = function () {
    return this._inputs;
};

/**
 * Creates a new input object of a specific type, create a new local id,
 * add the input to the list and return the local id
 *
 * @param {Input} input
 *
 * @returns {number}
 */
InputList.prototype.addInput = function (input) {
    // The current length of the _inputs array is N and its index goes from 0 to (N-1).
    // Therefore the id the next input which is being added to the list is N
    var localId = this._inputs.length;

    input.setLocalId(localId);

    this._inputs.push(input);

    return localId;
};

/**
 * Checks if the number of in-api-inputs reached the input limit
 *
 * @returns {boolean}
 */
InputList.prototype.isInputLimitReached = function () {
    var numberOfInputsInApi = this.getNumberOfInputsInApi();

    // this should never happen. if it happens then there is a bug somewhere
    assertIsFalse(numberOfInputsInApi > this._maxNumberOfInputs);

    return numberOfInputsInApi >= this._maxNumberOfInputs;
};

/**
 * Return the number of all inputs in all states (queued, failed, ...)
 *
 * @return {number}
 */
InputList.prototype.getNumberOfAllInputs = function () {
    return this._inputs.length;
};

/**
 * Returns the number of inputs which are not processed yet
 *
 * @return {number}
 */
InputList.prototype.getNumberOfQueuedInputs = function () {
    var f = function (input) {
        return input.isQueued();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Returns the number of inputs which are currently submitted to the API
 *
 * some non-submitted statuses are: queued, failed, deleted, ...
 *
 * @return {number}
 */
InputList.prototype.getNumberOfInputsInApi = function () {
    /**
     * @param {Input} input
     */
    var f = function (input) {
        return input.isInApi();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are fully uploaded
 *
 * @return {number}
 */
InputList.prototype.getNumberOfDoneInputs = function () {
    /**
     * @param {Input} input
     */
    var f = function (input) {
        return input.isDone();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are currently being deleted in the API
 *
 * @return {number}
 */
InputList.prototype.getNumberOfDeletingInputs = function () {
    /**
     * @param {Input} input
     */
    var f = function (input) {
        return input.isDeleting();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are fully uploaded
 *
 * @return {number}
 */
InputList.prototype.getNumberOfActiveFileUploads = function () {
    var f = function (input) {
        return input.isFileUploading();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are currnetly on their way to the API but which have not
 * returned yet
 *
 * @return {number}
 */
InputList.prototype.getNumberOfSubmittingInputs = function () {
    var f = function (input) {
        return input.isSubmitting();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are currently uploading or downloading
 *
 * @return {number}
 */
InputList.prototype.getNumberOfLoadingInputs = function () {
    var f = function (input) {
        return input.isLoading();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the remote inputs which currently downloading
 *
 * @return {number}
 */
InputList.prototype.getNumberOfActiveRemoteInputsDownloading = function () {
    /**
     * checks if input is a remote which is currently downloading
     * @param {Input} input
     */ var f = function (input) {
        return input.isRemoteInputDownloading();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the remote inputs which are not done
 *
 * @return {number}
 */
InputList.prototype.getNumberOfRemoteInputsForEngineCheck = function () {
    /**
     * checks if input is a remote which is not done
     * @param {Input} input
     */ var f = function (input) {
        if (input.getType() !== INPUT_TYPE_EXTERNAL_URL) {
            return false;
        }

        return input.isLoading() || input.isQueued() || input.isSubmitting();
    };

    return countInputsWithFunction(this._inputs, f);
};

/**
 * Counts all the inputs which are fully uploaded
 *
 * @return {number}
 */
InputList.prototype.getNumberOfPasswordMissing = function () {
    var f = function (input) {
        // if a password is still submitting it still counts as missing since it's not arrived in the api yet
        let passwordMissingStatuses = [INPUT_STATUS_UPLOADER_PASSWORD_MISSING, INPUT_STATUS_UPLOADER_SETTING_PASSWORD];

        return passwordMissingStatuses.includes(input.getStatus());
    };

    return countInputsWithFunction(this._inputs, f);
};

InputList.prototype.getBytesInJob = function () {
    var bytesInJob = 0;

    /**
     * count size if input is added to job
     * @param {number} localId
     * @param {Input} input
     */
    var f = function (localId, input) {
        if (input.isLoading() || input.isDone() || input.isPasswordMissing()) {
            bytesInJob += input.getSize();
        }
    };

    $.each(this._inputs, f);

    return bytesInJob;
};

InputList.prototype.getSizeOfAllInputs = function () {
    let sizeOfAllInputs = 0;
    var f = function (localId, input) {
        sizeOfAllInputs += input.getSize();
    };

    $.each(this._inputs, f);

    return sizeOfAllInputs;
};

export { InputList };
