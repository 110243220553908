import { JobInfoHelper } from '../info-helper/job-info-helper';
import {
    assertIsFalse,
    assertIsNotNull,
    assertIsNull,
    assertIsUUID4,
} from '../../../../lib/test-and-assert/assert-base';
import { assertIsRawJobInfo } from '../assert';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import { testIsObject } from '../../../../lib/test-and-assert/test-base';

/**
 *
 * @param {Api} api
 * @param {UploaderConfig} [config={}]
 *
 * @constructor
 */
function Job(api, config) {
    if (!testIsObject(config)) {
        config = {};
    }

    /** @type {Api} */
    this._api = api;

    /** @type {JobInfoHelper|null} */
    this._jobInfoHelper = null;

    // If job creation failed this is set to true
    this._isJobCreationFailed = false;

    // If the job is in the process of being created then this is set to true
    this._isCreating = false;

    // This tells if the upload server was reachable after creating the job
    this._isUploadServerReachable = true;
}

/**
 * Resets the job to a fresh state
 */
Job.prototype.reset = function () {
    // reset should not be called when a job is currently being created
    assertIsFalse(this._isCreating);

    // these should be all the values which are used in the constructor
    this._jobInfoHelper = null;
    this._isJobCreationFailed = false;
    this._isCreating = false;
    this._isUploadServerReachable = true;
};

/**
 * This creates a new job object from an existing job in the API
 *
 * @param {string} jobId
 */
Job.prototype.initFromExistingJob = function (jobId) {
    assertIsNull(this._jobInfoHelper);
    assertIsFalse(this._isCreating);
    assertIsUUID4(jobId);

    this.reset();

    var deferred = $.Deferred();

    var _this = this;

    _this._isCreating = true;

    this._api
        .getJobInfo(jobId)
        .done(function (rawJobInfo) {
            _this._jobInfoHelper = new JobInfoHelper(rawJobInfo);

            deferred.resolve(rawJobInfo);

            _this._isCreating = false;
        })
        .fail(function (error) {
            _this._isJobCreationFailed = true;

            deferred.reject(error);

            _this._isCreating = false;
        });

    return deferred;
};

/**
 * This creates a job without checking the upload server
 */
Job.prototype.create = function (jobData) {
    assertIsNull(this._jobInfoHelper);
    assertIsFalse(this._isCreating);

    this.reset();

    var deferred = $.Deferred();

    var _this = this;

    _this._isCreating = true;

    this._api
        .createJob(jobData)
        .done(function (rawJobInfo) {
            _this._jobInfoHelper = new JobInfoHelper(rawJobInfo);

            deferred.resolve(rawJobInfo);

            _this._isCreating = false;
        })
        .fail(function (apiError) {
            assertIsApiError(apiError);

            _this._isJobCreationFailed = true;

            deferred.reject(apiError);

            _this._isCreating = false;
        });

    return deferred;
};

/**
 * This creates a job and checking the upload server afterwards. If the connection is
 * broken a new job is created
 *
 * @return {JQueryDeferred<RawJobInfo>}
 */
Job.prototype.createWithServerCheck = function (jobData) {
    assertIsNull(this._jobInfoHelper);
    assertIsFalse(this._isCreating);

    this.reset();

    var deferred = $.Deferred();

    var _this = this;

    _this._isCreating = true;

    this._api
        .createJobWithUploadServerCheck(jobData, 2)
        .done(function (rawJobInfo, uploadServerReachable) {
            _this._jobInfoHelper = new JobInfoHelper(rawJobInfo);

            _this._isCreating = false;

            _this._isUploadServerReachable = uploadServerReachable;

            deferred.resolve(rawJobInfo);
        })
        .fail(function (apiError, response) {
            assertIsApiError(apiError);

            _this._isJobCreationFailed = true;

            _this._isCreating = false;

            deferred.reject(apiError, response);
        });

    return deferred;
};

Job.prototype.isCreated = function () {
    return this._jobInfoHelper !== null;
};

Job.prototype.isCreating = function () {
    return this._isCreating;
};

Job.prototype.isFailed = function () {
    return this._isJobCreationFailed;
};

/**
 * @return {boolean}
 */
Job.prototype.isUploadServerReachable = function () {
    return this._isUploadServerReachable;
};

/**
 * @return {string|null}
 */
Job.prototype.getId = function () {
    assertIsFalse(this._isJobCreationFailed);

    if (this._jobInfoHelper === null) {
        return null;
    }

    return this._jobInfoHelper.getId();
};

Job.prototype.getConversionId = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getConversionId();
};

Job.prototype.getConversionTarget = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getConversionTarget();
};

Job.prototype.getUploadToken = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getToken();
};

Job.prototype.getServerUrl = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getServer();
};

Job.prototype.getUploadUrl = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getUploadUrl();
};

Job.prototype.getFallbackUploadUrl = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getFallbackUploadUrl();
};

/**
 * Directly send a getJobInfd request to the API
 *
 * Warning: Only use this if you _really_ know what you are doing because
 *          it might overload the API if too many requests are done
 */
Job.prototype.getJobInfo = function () {
    assertIsFalse(this._isJobCreationFailed);
    assertIsNotNull(this._jobInfoHelper);

    var deferred = $.Deferred();

    var _this = this;

    this._api
        .getJobInfo(_this.getId())
        .done(function (jobInfoRaw) {
            assertIsRawJobInfo(jobInfoRaw);

            _this._jobInfoHelper = new JobInfoHelper(jobInfoRaw);

            deferred.resolve(jobInfoRaw);
        })
        .fail(function (apiError) {
            assertIsApiError(apiError);

            deferred.reject(apiError);
        });

    return deferred;
};

Job.prototype.getCachedJobInfo = function () {
    assertIsFalse(this._isJobCreationFailed);
    assertIsNotNull(this._jobInfoHelper);

    var deferred = $.Deferred();

    var _this = this;

    this._api
        .getCachedJobInfo(_this.getId())
        .done(function (jobInfoRaw) {
            assertIsRawJobInfo(jobInfoRaw);

            _this._jobInfoHelper = new JobInfoHelper(jobInfoRaw);

            deferred.resolve(jobInfoRaw);
        })
        .fail(function (apiError) {
            assertIsApiError(apiError);

            deferred.reject(apiError);
        });

    return deferred;
};

/**
 * Returns the raw job info data as as a simple object exactly as it comes from the API/satcore
 *
 * @return {Object}
 */
Job.prototype.getRawJobInfo = function () {
    assertIsFalse(this._isJobCreationFailed);

    return this._jobInfoHelper.getRawJobInfo();
};

/**
 * This periodically checks if an external input has finished downloading / had an error and
 * then updates the status of this input accordingly
 *
 * @param  {string} inputId
 *
 * @return {JQuery.Deferred}
 */
Job.prototype.waitForRemoteInput = function (inputId) {
    var jobId = this._jobInfoHelper.getId();

    return this._api.waitForRemoteInput(jobId, inputId);
};

export { Job };
