import { testIsObject, testObjectHasKey } from '../../../../lib/test-and-assert/test-base';

import { globalLogger } from '../../../../qaamgo/helper/global-logger';

import { Input } from '../lib/input';
import { INPUT_STATUS_UPLOADER_LOADING, INPUT_STATUS_UPLOADER_SUBMITTING, INPUT_TYPE_GDRIVE } from '../consts';
import { GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE, INPUT_EVENT_LOADING, INPUT_EVENT_SUBMITTING } from '../event';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import { API_ERROR_LIMITS_INPUT_TOO_LARGE } from '../../../api/consts';
import { FailInputError } from '../uploader';
import $ from 'jquery';

var tokenClient;

var _oauthToken = null;

var pickerInited = false;

var gisInited = false;


/**
 * @constructor
 */
function GdriveConfig() {
    /** @type {number} */
    this.maxFileSize = 8589934592;

    /** @type {?string} */
    this.clientId = null;

    /** @type {?string} */
    this.developerKey = null;

    /** @type {?string} */
    this.appId = null;
}

export { GdriveConfig };

class AddGdrive {
    /**
     * @param {Uploader} uploader
     * @param {GdriveConfig} config
     */
    constructor(uploader, config) {
        /** @type {Uploader} */
        this._uploader = uploader;

        this._config = new GdriveConfig();

        if (testIsObject(config) && testObjectHasKey(config, 'maxFileSize')) {
            this._config = config;
        }

        _oauthToken = null;
    }

    add(name, token, fileId, mimeType) {
        /** @type {Uploader} */
        var _uploader = this._uploader;
        /** @type {GdriveConfig} */
        var _config = this._config;

        if (
            typeof name === 'string' &&
            typeof token === 'string' &&
            typeof fileId === 'string' &&
            typeof mimeType === 'string'
        ) {
            helper(token, name, 0, fileId, mimeType);
            return;
        }

        if (this._config.clientId === null || this._config.developerKey === null) {
            return;
        }

        function loadPicker() {
            if (sessionStorage.getItem('PLAYWRIGHT') === 'true') {
                return;
            }
            $.when(
                $.cachedScript('https://apis.google.com/js/api.js'),
                $.cachedScript('https://accounts.google.com/gsi/client')
            ).done(function() {
                onApiLoad();
                gisLoaded();
                createPicker()
            });
        }

        // Use the API Loader script to load google.picker
        function onApiLoad() {
            gapi.load('picker', onPickerApiLoad);
        }

        function onPickerApiLoad() {
            pickerInited = true;
        }

        function gisLoaded() {
            tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: _config.clientId,
                scope: 'https://www.googleapis.com/auth/drive.file ' +
                        'https://www.googleapis.com/auth/drive.install ',
                include_granted_scopes: false,
                callback: pickerCallback,
            });
            gisInited = true;
        }



        function createPicker() {
            const showPicker = () => {
                const picker = new google.picker.PickerBuilder()
                    .addView(google.picker.ViewId.DOCS)
                    .setOAuthToken(_oauthToken)
                    .setDeveloperKey(_config.developerKey)
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .setCallback(pickerCallback)
                    .setAppId(_config.appId)
                    .build();
                picker.setVisible(true);
            }

            // Request an access token.
            tokenClient.callback = async (response) => {
                if (response.error !== undefined) {
                    throw (response);
                }
                _oauthToken = response.access_token;
                showPicker();
            };

            if (_oauthToken === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                tokenClient.requestAccessToken({prompt: ''});
            }
        }
        loadPicker();

        function pickerCallback(data) {
            if (typeof data.action === 'undefined' || data.action !== 'picked') {
                return;
            }

            if (typeof data.docs === 'undefined') {
                return;
            }

            $.each(data.docs, function (key, value) {
                var size = 0;
                var name = 'gDrive';

                if (value.hasOwnProperty('sizeBytes') && typeof value.sizeBytes === 'number') {
                    size = value.sizeBytes;
                }

                if (value.hasOwnProperty('name')) {
                    name = value.name;
                }

                helper(_oauthToken, name, size, value.id, value.mimeType);
            });
        }

        function helper(token, name, size, file_id, content_type) {
            var callback = function (localId) {
                var deferred = $.Deferred();

                _uploader.getInput(localId).setName(name);
                _uploader.getInput(localId).setSize(size);

                // if the sum of the bytes in the job + the size of this input is
                // larger than the max size...
                var totalSize = _uploader.getBytesInJob() + size;

                if (totalSize > _config.maxFileSize) {
                    globalLogger.log('qgMultiUpload gdrive fail', {
                        info: 'file too large',
                        filename: name,
                        file_size: size,
                        max_file_size: _config.maxFileSize,
                    });

                    var failInputError = new FailInputError(API_ERROR_LIMITS_INPUT_TOO_LARGE);

                    failInputError.size = totalSize;

                    _uploader.failInput(localId, failInputError);

                    _uploader.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);

                    return deferred.resolve();
                }

                var jobId = _uploader.getJob().getId();

                _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_SUBMITTING);

                _uploader.dispatchInputEvent(INPUT_EVENT_SUBMITTING, localId);

                _uploader
                    .getApiHelper()
                    .addInput(jobId, {
                        type: 'gdrive_picker',
                        source: file_id,
                        credentials: {
                            token: token,
                        },
                        filename: name,
                        content_type: content_type,
                    })
                    /**
                     * @param {object} inputInfo
                     */
                    .done(function (inputInfo) {
                        var inputId = inputInfo.id;

                        _uploader.getInput(localId).setInputId(inputId);
                        _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);

                        _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

                        _uploader.waitForRemoteInput(localId);

                        deferred.resolve();
                    })
                    .fail(function (apiError) {
                        // var errorData = {
                        //     error: UPLOADER_ERROR_UNKNOWN
                        // };
                        //
                        // if (error.hasOwnProperty('code') && error.hasOwnProperty('text')) {
                        //     if (error.code === 50 || error.code === 34) {
                        //         errorData.error = uploaderErrors.UPLOADER_ERROR_LIMITS_INPUT_TOO_LARGE;
                        //     }
                        // }
                        //
                        // globalLogger.log('qgMultiUpload gdrive fail', {
                        //     info: 'file too large',
                        //     filename: name,
                        //     file_size: size,
                        //     max_file_size: _config.maxFileSize
                        // });
                        //
                        // _uploader.failInput(localId, errorData);
                        //
                        // _uploader.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);
                        //
                        // deferred.resolve();

                        // TODO: this needs to dispatch event if file is too large

                        assertIsApiError(apiError);

                        var failInputError = new FailInputError(apiError);

                        _uploader.failInput(localId, failInputError);

                        deferred.resolve();
                    });

                return deferred;
            };

            var input = new Input(INPUT_TYPE_GDRIVE);

            input.setCallback(callback).setName(name).setSize(size);

            _uploader._queueInput(input);
        };
    }


    click() {
        this._uploader.addGdrive();
    }

}

export { AddGdrive };
