import { Logger } from '../../lib/logger/logger';
import { setupWindowOnError } from '../../lib/logger/window-on-error';
import { createUUID4 } from '../helper/uuid';

// About the max number of logs:
// This is the maximum number of logs the website can sent to the server. A normal upload
// of a single should have 5 to 10 logs in total (job created, ...). If the user uploads 60
// file then they get at least 2 logs per input + some additional logs from other parts of the
// uploader. Therefore it is realistic that there are valid cases in which 100-150 logs are sent.
// I guesstimate that 500 logs should be the total maximum for a valid use case

/** @type {LoggerOptions} */
var options = {
    url: '/api/z',
    cors: false,
    maxNumberOfLogs: 500,
    extraUid: createUUID4(),
};

if (typeof window.qgLoggerOptions === 'object') {
    var o = window.qgLoggerOptions;

    if (typeof o.url === 'string') {
        options.url = o.url;
    }

    options.cors = o.cors === true;
}

var logger = new Logger(options);

setupWindowOnError(logger);

window.qgLogger = logger;
