import { assertIsString } from '../../../../lib/test-and-assert/assert-base';
import { assertIsRawInputInfo } from '../assert';

import { Input } from '../lib/input';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import { INPUT_STATUS_UPLOADER_LOADING, INPUT_STATUS_UPLOADER_SUBMITTING, INPUT_TYPE_EXTERNAL_URL } from '../consts';
import { INPUT_EVENT_LOADING, INPUT_EVENT_SUBMITTING } from '../event';
import { API_ERROR_UNKNOWN, API_ERROR_URL_TOO_LONG } from '../../../api/consts';
import { FailInputError } from '../uploader';
import { testIsUndefined } from '../../../../lib/test-and-assert/test-base';

/**
 * @param {Uploader} uploader
 *
 * @constructor
 */
class AddExternalUrl {
    constructor(uploader) {
        this._uploader = uploader;
    }

    /**
     * this is used to add an external url
     *
     * @param {string} url
     * @param {string} [engine='auto']
     */
    add(url, engine) {
        assertIsString(url);
        if (testIsUndefined(engine)) {
            engine = 'auto';
        }

        url = url.trim();

        /** @type {Uploader} */
        var _uploader = this._uploader;

        var callback = function (localId) {
            // this deferred is used inside the queue. it is used to prevent that inputs are added in parallel
            // instead inputs are added one after the other
            var deferred = $.Deferred();

            var jobId = _uploader.getJob().getId();

            _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_SUBMITTING);
            _uploader.getInput(localId).setName(url);

            _uploader.dispatchInputEvent(INPUT_EVENT_SUBMITTING, localId);

            // If the url only consists of whitespace then the input is failed without
            // being submitted to the API
            if (url.length === 0) {
                var failInputError = new FailInputError(API_ERROR_UNKNOWN);

                _uploader.failInput(localId, failInputError);

                deferred.resolve();

                return deferred;
            }

            if (url.length > 4000) {
                const failInputError = new FailInputError(API_ERROR_URL_TOO_LONG);

                _uploader.failInput(localId, failInputError);

                deferred.resolve();

                return deferred;
            }

            _uploader
                .getApiHelper()
                .addExternalUrl(jobId, url, engine)
                /**
                 * @param {object}   inputInfo
                 */
                .done(function (rawInputInfo) {
                    assertIsRawInputInfo(rawInputInfo);

                    var inputId = rawInputInfo.id;

                    _uploader.getInput(localId).setInputId(inputId);
                    _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);

                    _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

                    _uploader.waitForRemoteInput(localId);

                    deferred.resolve();
                })
                .fail(function (apiError) {
                    assertIsApiError(apiError);

                    var failInputError = new FailInputError(apiError);

                    _uploader.failInput(localId, failInputError);

                    deferred.resolve();
                });

            return deferred;
        };

        var input = new Input(INPUT_TYPE_EXTERNAL_URL);

        input.setName(url).setCallback(callback);

        this._uploader._queueInput(input);
    }

    /**
     * @param {String} url
     * @param {String} [engine='auto']
     */
    click(url, engine) {
        this.add(url, engine);
    }
}

export { AddExternalUrl };
