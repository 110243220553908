export function formatFileSize(bytes) {
    const GB_SIZE = 1024 * 1024 * 1024;
    const MB_SIZE = 1024 * 1024;
    const KB_SIZE = 1024;

    if (typeof bytes !== 'number') {
        return '';
    }

    if (bytes >= GB_SIZE) {
        return (bytes / GB_SIZE).toFixed(2) + ' GB';
    }

    if (bytes >= MB_SIZE) {
        return (bytes / MB_SIZE).toFixed(2) + ' MB';
    }

    if (bytes >= KB_SIZE) {
        return (bytes / KB_SIZE).toFixed(2) + ' KB';
    }

    if (bytes !== 1) {
        return bytes.toFixed(2) + ' bytes';
    }

    return bytes.toFixed(2) + ' byte';
}

export function registerPlaceholderToggle() {
    $('.toggle-placeholder').each(function (key, element) {
        const $element = $(element);
        const text = $element.prop('placeholder');

        $element.on('focus', function () {
            $element.prop('placeholder', '');
        });

        $element.on('blur', function () {
            $element.prop('placeholder', text);
        });
    });
}

export function decryptSupportLink(s, j) {
    let n = 0;
    let r = '';

    for (let i = 0; i < s.length; i++) {
        n = s.charCodeAt(i);

        if (n >= 8364) {
            n = 128;
        }

        //to encrypt replace n-1 with n+1
        r += String.fromCharCode(n - 1);
    }

    if (typeof j !== 'undefined' && j != '') {
        return r + j;
    } else {
        return r;
    }
}

export function setUpSupportLinks() {
    const $links = $('.support-link');

    if ($links.length === 0) {
        return;
    }

    $links.on('click', function (e) {
        e.preventDefault();

        window.location = decryptSupportLink($(this).data('encrypted'));

        return false;
    });
}

export function isTouchDevice() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query) {
        return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

    return mq(query);
}

export function sanitizeInput(input) {
    return input
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}
