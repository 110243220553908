import '../../scss/critical.scss';

import * as Sentry from "@sentry/browser";

if (satGlobals.getSentryDSN() !== 'none') {
    let version = SAT_VERSION ?? 'sat-client@v0.0.0';

    if (version === 'SAT_VERSION undefined') {
        version = 'sat-client@v0.0.0';
    }

    Sentry.init({
        dsn: satGlobals.getSentryDSN(),

        integrations: [
            Sentry.browserProfilingIntegration(),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: ["localhost", "https:\/\/dragon."],
        release: version,
        environment: satGlobals.getSentryEnv(),
        tracesSampleRate: 0.1,
        profilesSampleRate: 0.01,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.1,
    });
}

import axios from 'axios';

const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap-notify';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/collapse';

window.satConnectionType = 'unknown';

if (navigator.connection && navigator.connection.effectiveType) {
    if (navigator.connection.effectiveType === '4g') {
        window.satConnectionType = 'fast';
    } else {
        window.satConnectionType = 'slow';
    }
}

axios.defaults.headers.common['locale'] = sat.locale;
$(document).ajaxSend(function (event, jqxhr, settings) {
    if (settings.url.includes('dragon')) {
        jqxhr.setRequestHeader('locale', sat.locale);
    }
});

initPiniaPageStore();

import { satToken } from '../qaamgo/sat/helper/sat-token';
import {
    getCurrentFragmentData,
    getCurrentQueryData,
    getFragmentStringFromUrl,
    getValueFromCurrentUrl,
} from '../qaamgo/helper/query-fragment-params';
import { cookieHelper } from '../qaamgo/helper/cookie';

window.qgQueryFragmentHelper = {
    getCurrentQueryData: getCurrentQueryData,
    getCurrentFragmentData: getCurrentFragmentData,
    getValueFromCurrentUrl: getValueFromCurrentUrl,
    getFragmentStringFromUrl: getFragmentStringFromUrl,
};

window.qgCookieHelper = cookieHelper;

window.satToken = satToken;

import(
    /* webpackMode: "eager" */
    '../qaamgo/modules/logger.js'
);
import(
    /* webpackMode: "eager" */
    '../lib-sat/misc/bookmarks'
);

import { initLimitSystem } from '../lib-sat/limits/main-limits';

if (window.QG_LIMIT_SYSTEM === true) {
} else {
    initLimitSystem();

    window.QG_LIMIT_SYSTEM = true;
}

if (window.qgadconfig !== undefined && sat.project_name !== 'Online-Convert') {
    import(
        /* webpackMode: "lazy" */
        './ads/qg-cms-ads'
    ).then(() => {});
}

import(
    /* webpackMode: "lazy-once" */
    `./translations/sat/${sat.locale}.js`
    ).then(function () {
    const isUserModalEnabled = window.sat?.enableUserModalFlow;

    if (isUserModalEnabled) {
        import(
            /* webpackMode: "lazy" */
            '../lib-sat/user/modal/register_payment_flow/main-register-payment-modal'
        ).then(() => {
            import(
                /* webpackMode: "lazy" */
                '../lib-sat/user/user-buttons/main-user-buttons'
            );
        });
    } else {
        import(
            /* webpackMode: "lazy" */
            '../lib-sat/user/user-buttons/main-user-buttons'
        );
    }

    import(
        /* webpackMode: "lazy" */
        '../lib-sat/user/user-notifications/main'
    );

    import(
        /* webpackMode: "lazy" */
        '../lib-sat/user/modal/modals'
    );

    import(
        /* webpackMode: "lazy" */
        '../qaamgo/helper/toasts'
    );
    import(
        /* webpackMode: "lazy" */
        '../qaamgo/helper/google-onetap'
    );

    $('.footer-contact-us').on('click', () => {
        import(
            /* webpackMode: "lazy-once" */
            `./user/qg-contact-us`
        ).then(function () {
            setTimeout(() => {
                eventBus.emit(CONTACT_US_OPEN_MODAL);
            }, 100);
        });
    });

    import(
        /* webpackMode: "lazy-once" */
        `./user/qg-user-questionnaire`
    );
});

import '../qaamgo/helper/unpublished_page';
import { eventBus } from '../lib/event-bus/event-bus';
import { CONTACT_US_OPEN_MODAL } from '../lib-sat/contact-us/event';
import { getLocationHashWithoutModalPageFragment } from '../lib-sat/user/modal/register_payment_flow/modal-fragment-helper';
import { testIsNonEmptyString } from '../lib/test-and-assert/test-base';
import { initPiniaPageStore } from '../lib-sat/store/page-store';
import { setUpSupportLinks } from '../lib-sat/misc/misc';
import {satGlobals} from "../qaamgo/sat/helper/sat-globals";

jQuery.cachedScript = function (url, options) {
    options = $.extend(options || {}, {
        dataType: 'script',
        cache: true,
        url: url,
    });

    return jQuery.ajax(options);
};

$('body').tooltip({
    selector: '[data-toggle=tooltip]',
    trigger: 'hover',
});

$(function () {
    $(document).on('click', '[data-hide]', function () {
        $(this)
            .closest('.' + $(this).attr('data-hide'))
            .hide();
        return false;
    });

    setUpSupportLinks();
    if (window.satConnectionType) {
        qgLogger.log(`connection_${window.satConnectionType}`);
    }
});

let updateLanguageSwitcher = function () {
    try {
        const fragment = getLocationHashWithoutModalPageFragment();

        if (!testIsNonEmptyString(fragment)) {
            return;
        }

        $('.language-selector-link').each(function (idx, element) {
            const $element = $(element);
            const href = $element.attr('href');

            // if there are already fragments then we do not update them
            if (href.indexOf('#') !== -1) {
                return;
            }

            // WARNING:
            // the fragment data can potentially used to inject JS into the page. The code
            // below is safe because we add a string to an url and not doing stuff like:
            // $element.attr('href', fragment)
            // see: https://security.stackexchange.com/a/160191
            $element.attr('href', href + fragment);
        });
    } catch (e) {}
};

updateLanguageSwitcher();

(function () {
    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    };
})();

// Make sure that the properties exist on the window.
window.googlefc = window.googlefc || {};
window.googlefc.ccpa = window.googlefc.ccpa || {};
window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];

googlefc.controlledMessagingFunction = async (message) => {
    // if it is a playwright test don't show the consent
    if (sessionStorage.getItem('PLAYWRIGHT') === 'true') {
        message.proceed(false);
    } else {
        // Otherwise, show messages as usual.
        message.proceed(true);
    }
}

window.googlefc.callbackQueue.push({
    'AD_BLOCK_DATA_READY':
        () => {
            switch (googlefc.getAllowAdsStatus()) {
                case googlefc.AllowAdsStatusEnum.ADS_NOT_ALLOWED:
                    //user consented
                    cookieHelper.setCookie('qg_consent', true, 31557600);
                    break;
                case googlefc.AllowAdsStatusEnum.ADS_ALLOWED:
                    break;
                case googlefc.AllowAdsStatusEnum.UNKNOWN:
                    //user did not consent
                    cookieHelper.setCookie('qg_consent_rejected', false, 1209600);
                    break;
            }
        }
});

