import { assertIsGlobalEvent, assertIsInputEvent, assertIsRawInputInfo, assertIsRawJobInfo } from './assert';
import {
    assertIsArray,
    assertIsObject,
    assertIsUUID4,
    assertObjectHasKey,
} from '../../../lib/test-and-assert/assert-base';
import {
    testIsNonEmptyString,
    testIsNotUndefined,
    testIsNumber,
    testIsObject,
    testIsString,
    testIsUndefined,
} from '../../../lib/test-and-assert/test-base';

import { globalLogger } from '../../../qaamgo/helper/global-logger';

import { Input } from './lib/input.js';
import { Job } from './lib/job';
import { InputList } from './lib/input-list';
import { AddExternalUrl } from './add-helper/external-url';
import { AddDropbox, DropboxConfig } from './add-helper/dropbox';
import { AddFileUpload, FileUploadConfig } from './add-helper/file-upload';
import { AddGdrive, GdriveConfig } from './add-helper/gdrive';
import { AddOnedrive, OnedriveConfig } from './add-helper/onedrive';
import { AddInputId } from './add-helper/input-id';
import { isYouTubeUrl } from '../../../qaamgo/helper/youtube-url';
import { JobInfoHelper } from './info-helper/job-info-helper';
import { isPasswordMissing } from '../../../qaamgo/helper/input-password';
import {
    INPUT_STATUS_API_CANCELED,
    INPUT_STATUS_API_DOWNLOADING,
    INPUT_STATUS_API_FAILED,
    INPUT_STATUS_API_READY,
    INPUT_STATUS_UPLOADER_DELETED,
    INPUT_STATUS_UPLOADER_DELETING,
    INPUT_STATUS_UPLOADER_DONE,
    INPUT_STATUS_UPLOADER_FAILED,
    INPUT_STATUS_UPLOADER_LOADING,
    INPUT_STATUS_UPLOADER_PASSWORD_MISSING,
    INPUT_STATUS_UPLOADER_QUEUED,
    INPUT_STATUS_UPLOADER_SETTING_PASSWORD,
    INPUT_TYPE_EXTERNAL_URL,
    INPUT_TYPE_FILE_UPLOAD,
    INPUT_TYPE_GDRIVE,
    SET_PASSWORD_ERROR_ALREADY_SETTING,
    SET_PASSWORD_ERROR_UNKNOWN_ERROR,
    SET_PASSWORD_ERROR_WRONG_PASSWORD,
} from './consts';
import {
    GLOBAL_EVENT_JOB_CREATE_DONE,
    GLOBAL_EVENT_JOB_CREATE_FAIL,
    GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED,
    GLOBAL_EVENT_JOB_CREATE_START,
    GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE,
    GLOBAL_EVENT_LIMITS_TOO_MANY_INPUTS,
    GLOBAL_EVENT_LIMITS_YOUTUBE_FORBIDDEN,
    INPUT_EVENT_DELETED,
    INPUT_EVENT_DELETING,
    INPUT_EVENT_DONE,
    INPUT_EVENT_FAIL,
    INPUT_EVENT_LOADING,
    INPUT_EVENT_PASSWORD_MISSING,
    INPUT_EVENT_QUEUED,
    INPUT_EVENT_SETTING_PASSWORD,
    INPUT_EVENT_UPDATE_STATUS,
    INPUT_EVENT_UPLOAD_CANCELED,
    UPLOADER_EVENT,
    UploaderEvent,
} from './event';
import {
    API_ERROR_LIMITS_INPUT_TOO_LARGE,
    API_ERROR_LIMITS_TOO_MANY_INPUTS,
    API_ERROR_LIMITS_YOUTUBE_FORBIDDEN,
    API_ERROR_PAYMENT_REQUIRED,
    API_ERROR_UNKNOWN,
} from '../../api/consts';
import { assertIsApiError } from '../../../lib/test-and-assert/assert-api';
import { eventBus } from '../../../lib/event-bus/event-bus';
import { useJobStore } from '../../store/modules/job-store';

/**
 * @param {string} [apiError]
 * @constructor
 */
function FailInputError(apiError) {
    this.error = API_ERROR_UNKNOWN;
    this.size = null;
    this.maxFileSize = null;
    this.maxNumberOfInputs = null;

    if (apiError) {
        assertIsApiError(apiError);

        this.error = apiError;
    }
}

export { FailInputError };

class Uploader {
    /**
     * @param {Api} apiHelper
     * @param {UploaderConfig} config
     */
    constructor(apiHelper, config) {
        assertIsObject(config);

        this._config = config;

        /** @type {Api} */
        this._apiHelper = apiHelper;

        // this is used to enable/disable the uploader
        this._isEnabled = true;

        /** @type {InputList} */
        this._inputList = new InputList(config);

        /** @type {Job} */
        this._job = new Job(apiHelper, config);

        // This is true while queued / non-submitted inputs are processed
        this._queueIsBeingProcessed = false;

        this._sentLogAboutMaxInputs = false;

        this._maxFileSize = 8589934592;

        this._maxNumberOfInputs = 60;

        if (testIsNumber(config.maxNumberOfInputs) && config.maxNumberOfInputs > 0) {
            this._maxNumberOfInputs = config.maxNumberOfInputs;
        }

        if (testIsNumber(config.maxFileSize)) {
            this._maxFileSize = config.maxFileSize;
        }

        this._blockYouTubeUrls = true;

        // If the uploader is used for a single input then this flag is set to control some
        // special behaviour
        // TODO: remove this when the satcore supports feature-sensitive jobs, e.g. jobs with a single input
        this._singleInput = config.maxNumberOfInputs === 1;

        this._enablePasswords = config.enablePasswords === true;

        // this is used in oc-frontend
        this._showPasswordOnlyForSingleInput = config.showPasswordOnlyForSingleInput === true;

        if (config.hasOwnProperty('blockYouTubeUrls')) {
            this._blockYouTubeUrls = config.blockYouTubeUrls;
        }

        /** @type {?AddDropbox} */
        this._addDropboxHelper = null;

        /** @type {?AddGdrive} */
        this._addGdriveHelper = null;

        /** @type {?AddOnedrive} */
        this._addGdriveHelper = null;

        /** @type {?AddFileUpload} */
        this._addFileUploadHelper = null; // PhpStorm says that this is unused but that's a lie

        /** @type {?AddInputId} */
        this._addInputIdHelper = null;

        /** @type {?AddExternalUrl} */
        this._addExternalUrlHelper = null;

        this._setupAddHelper(config);

        this._jobData = {};

        if (config.hasOwnProperty('jobData')) {
            this._jobData = config.jobData;
        }

        if (config.hasOwnProperty('jobId')) {
            this.initFromJobId(config.jobId);
        }
    }

    /**
     * @param {UploaderConfig} config
     */

    _setupAddHelper(config) {
        // DROPBOX CONFIG
        var dropboxConfig = new DropboxConfig();

        dropboxConfig.maxFileSize = this._maxFileSize;

        this._addDropboxHelper = new AddDropbox(this, dropboxConfig);

        // ONEDRIVE CONFIG
        var onedriveConfig = new OnedriveConfig();

        onedriveConfig.maxFileSize = this._maxFileSize;

        if (testIsNonEmptyString(config.microsoftClientId)) {
            onedriveConfig.clientId = config.microsoftClientId;
        }

        this._addOnedriveHelper = new AddOnedrive(this, onedriveConfig);

        // GDRIVE CONFIG
        var gdriveConfig = new GdriveConfig();

        gdriveConfig.maxFileSize = this._maxFileSize;

        if (testIsNonEmptyString(config.gdriveClientId)) {
            gdriveConfig.clientId = config.gdriveClientId;
        }

        if (testIsNonEmptyString(config.gdriveAppId)) {
            gdriveConfig.appId = config.gdriveAppId;
        }

        if (testIsNonEmptyString(config.gdriveDeveloperKey)) {
            gdriveConfig.developerKey = config.gdriveDeveloperKey;
        }

        this._addGdriveHelper = new AddGdrive(this, gdriveConfig);

        // FILEUPLOAD CONFIG
        var fileUploadConfig = new FileUploadConfig();

        fileUploadConfig.maxFileSize = this._maxFileSize;
        fileUploadConfig.enableUploadFallback = config.enableUploadFallback === true;
        fileUploadConfig.enablePasswords = config.enablePasswords === true;
        fileUploadConfig.fileUploadInputId = config.fileUploadInputId;

        // Do not remove this! Phpstorm might mark this as "unused" but it is still needed
        this._addFileUploadHelper = new AddFileUpload(this, fileUploadConfig);

        // OTHER CONFIG
        this._addInputIdHelper = new AddInputId(this);
        this._addExternalUrlHelper = new AddExternalUrl(this);
    }

    /**
     * Enable the uploader
     *
     * @param {string} jobId
     */

    initFromJobId(jobId) {
        assertIsUUID4(jobId);

        let deferred = $.Deferred();

        /** @type {Uploader} */
        var _this = this;

        this._inputList = new InputList(this._config);

        this._job
            .initFromExistingJob(jobId)
            .done(function (rawJobInfo) {
                var jobInfo = new JobInfoHelper(rawJobInfo);

                var inputs = jobInfo.getInputs();

                _this._tryToSetSatLimitsFromJobInfo(rawJobInfo);

                // WARNING: The code below does not check for limits! This should be fine
                //          because we assume that the limits were already checked on the page
                //          where the job is created.

                /**
                 * Helper functions which processes a single input
                 *
                 * @param idx
                 * @param {RawInputInfo} rawInput
                 */
                var processSingleInput = function (idx, rawInput) {
                    var apiStatus = rawInput.status;

                    // If an input is failed or canceled it will not appear in the uploader
                    if (apiStatus === INPUT_STATUS_API_FAILED || apiStatus === INPUT_STATUS_API_CANCELED) {
                        return;
                    }

                    var type = INPUT_TYPE_EXTERNAL_URL;

                    if (rawInput.type === 'upload') {
                        type = INPUT_TYPE_FILE_UPLOAD;
                    }

                    if (rawInput.type === 'gdrive_picker') {
                        type = INPUT_TYPE_GDRIVE;
                    }

                    var input = new Input(type);

                    input.setName(rawInput.filename).setSize(rawInput.size).setInputId(rawInput.id);

                    var localId = _this._inputList.addInput(input);

                    // we send the INPUT_QUEUED signal first to make sure that the entry
                    // in the uis are created properly
                    _this.dispatchInputEvent(INPUT_EVENT_QUEUED, localId);

                    if (apiStatus === INPUT_STATUS_API_DOWNLOADING) {
                        input.setStatus(INPUT_STATUS_UPLOADER_LOADING);
                        _this.dispatchInputEvent(INPUT_EVENT_LOADING, localId);
                        _this.waitForRemoteInput(localId);

                        return;
                    }

                    if (apiStatus === INPUT_STATUS_API_READY) {
                        var passwordMissing = isPasswordMissing(rawInput.metadata);

                        if (this._enablePasswords && passwordMissing) {
                            input.setStatus(INPUT_STATUS_UPLOADER_PASSWORD_MISSING);
                            _this.dispatchInputEvent(INPUT_EVENT_PASSWORD_MISSING, localId);
                        } else {
                            input.setStatus(INPUT_STATUS_UPLOADER_DONE);
                            _this.dispatchInputEvent(INPUT_EVENT_DONE, localId);
                        }

                        return;
                    }

                    // should never happen in prod
                    globalLogger.error('qgMultiUpload init fail unknown input status', JSON.stringify(rawInput));
                };

                $.each(inputs, processSingleInput);

                // I am not sure if this is really necessary but there might be the corner case
                // where the user ads stuff before initFromExistingJob() is completed. In such
                // a (rare) case it makes sense to start the process of submitting queued inputs
                _this._submitNextQueuedInput();

                deferred.resolve();
            })
            .fail(function (error) {
                // TODO
                deferred.reject(API_ERROR_UNKNOWN);
            });

        return deferred;
    }

    /**
     * Enable the uploader
     */

    enable() {
        this._isEnabled = true;
    }

    /**
     * Disable the uploader
     */

    disable() {
        this._isEnabled = false;
    }

    /**
     * Is the uploader enabled?
     *
     * @return boolean
     */

    isEnabled() {
        return this._isEnabled;
    }

    /**
     * Is the uploader configured for single file input?
     * 
     * @return boolean
     */

    isSingleInput() {
        return this._singleInput;
    }

    /**
     * Returns false if the uploader does not have queued or active inputs
     *
     * @return {boolean}
     */

    isEmpty() {
        var inputs = this.getInputList();

        return inputs.getNumberOfQueuedInputs() === 0 && inputs.getNumberOfInputsInApi() === 0;
    }

    /**
     * Returns true if the uploader is in a state where conversion can be started
     *
     * if ignoreMissingPasswords is set to true then the job can start even though there
     * are missing passwords. If it false or not set at all then the job will not starts if
     * passwords are missing
     *
     * @param {boolean} [ignoreMissingPasswords]
     *
     * @return {boolean}
     */

    isReadyToSubmit(ignoreMissingPasswords) {
        var inputs = this.getInputList();

        // If there are still queued inputs (aka 'not submitted to API yet) then we can't start the job
        if (inputs.getNumberOfQueuedInputs() > 0) {
            return false;
        }

        // If no active inputs in the API then there is nothing to convert
        if (inputs.getNumberOfInputsInApi() === 0) {
            return false;
        }

        // If there are inputs still being submitted to the API then we can't start the job
        if (inputs.getNumberOfSubmittingInputs() > 0) {
            return false;
        }

        // If there are files uploading then we can't start the job
        if (inputs.getNumberOfActiveFileUploads() > 0) {
            return false;
        }

        // If some inputs are in the process of being deleted then we can't start the job
        if (inputs.getNumberOfDeletingInputs() > 0) {
            return false;
        }

        // If some inputs are missing passwords then then we can't start the job in some cases
        if (inputs.getNumberOfPasswordMissing() > 0) {
            // if missing passwords was set to true then hte job can start even with
            // missing passwords
            if (ignoreMissingPasswords === true) {
                return true;
            } else {
                return false;
            }
        }

        // At this point the conversion can be started. The job contains at least one (or a combination of):
        // a) a completed file upload
        // b) a completed remote input
        // c) a remote inputs which is still downloading
        return true;
    }

    /**
     * Returns true if passwords are missing
     *
     * @return {boolean}
     */

    isWaitingForPasswords() {
        var inputs = this.getInputList();

        return inputs.getNumberOfPasswordMissing() > 0;
    }

    /**
     * Dispatch an input event
     *
     * @param {String} event
     * @param {Number} localId
     * @param [data]
     */

    dispatchInputEvent(event, localId, data) {
        assertIsInputEvent(event);

        const uploaderEvent = new UploaderEvent(event);

        uploaderEvent.localId = localId;

        uploaderEvent.data = data;

        eventBus.emit(UPLOADER_EVENT, uploaderEvent);
    }

    /**
     * Dispatch a global event
     *
     * @param {String} event
     * @param {any} [payload]
     */
    dispatchGlobalEvent(event, payload) {
        assertIsGlobalEvent(event);

        const uploaderEvent = new UploaderEvent(event);

        if (testIsNotUndefined(payload)) {
            uploaderEvent.data = payload;
        }

        eventBus.emit(UPLOADER_EVENT, uploaderEvent);
    }

    /**
     * Returns the job id
     *
     * @return {Job}
     */

    getJob() {
        return this._job;
    }

    /**
     * Returns an Input
     *
     * @param {number} localId
     *
     * @return {Input}
     */

    getInput(localId) {
        return this._inputList.getInput(localId);
    }

    /**
     * Returns the list of inputs
     *
     * @return {InputList}
     */

    getInputList() {
        return this._inputList;
    }

    /**
     * Returns the apiHelper
     *
     * @return {Api}
     */

    getApiHelper() {
        return this._apiHelper;
    }

    /**
     * This adds an input to the upload queue, creates a job (if needed) and starts the
     * submission process (if needed)
     *
     * @param {Input}   newInput
     */

    _queueInput(newInput) {
        if (!this._isEnabled) {
            return;
        }

        var localId = this._inputList.addInput(newInput);

        this.dispatchInputEvent(INPUT_EVENT_QUEUED, localId);

        var input = this.getInput(localId);

        // directly fail youtube urls (if enabled)
        if (this._blockYouTubeUrls && input.getType() === INPUT_TYPE_EXTERNAL_URL) {
            var url = input.getName();

            if (isYouTubeUrl(url)) {
                var youtubeFailInputError = new FailInputError(API_ERROR_LIMITS_YOUTUBE_FORBIDDEN);

                this.failInput(localId, youtubeFailInputError);

                this.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_YOUTUBE_FORBIDDEN);

                return;
            }
        }

        // If there is currently a job being created then there is nothing to do. The
        // current input will be automatically processed later
        if (this._job.isCreating()) {
            return;
        }

        // If there is a job then the current input can be processed
        if (this._job.isCreated()) {
            // If there are no inputs being submitted to the API right now
            // then the submission process is started. Otherwise nothing needs to
            // be done because the submission process is submitting all the inputs
            // automaticaly one after the other
            if (!this._queueIsBeingProcessed) {
                this._submitNextQueuedInput();
            }

            return;
        }

        var _this = this;

        this.dispatchGlobalEvent(GLOBAL_EVENT_JOB_CREATE_START);

        // Create a job! In case of success, all queued inputs are processed.
        this._job
            .createWithServerCheck(this._jobData)
            /**
             * @param {object} jobInfo
             * @param {string} jobInfo.id
             * @param {string} jobInfo.token
             * @param {string} jobInfo.server
             */
            .done(function (rawJobInfo) {
                assertIsRawJobInfo(rawJobInfo);

                globalLogger.addLogData('job_id', _this._job.getId());

                // satcore returns the limits for a new job in the server response
                _this._tryToSetSatLimitsFromJobInfo(rawJobInfo);

                _this.dispatchGlobalEvent(GLOBAL_EVENT_JOB_CREATE_DONE);

                _this._submitNextQueuedInput();
            })
            .fail(function (apiError, response) {
                assertIsApiError(apiError);

                if (apiError === API_ERROR_PAYMENT_REQUIRED) {
                    _this._failQueuedInputs(apiError);

                    _this._job.reset();

                    _this.dispatchGlobalEvent(GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED, response);

                    return;
                }

                _this._failQueuedInputs(apiError);

                _this._job.reset();

                _this.dispatchGlobalEvent(GLOBAL_EVENT_JOB_CREATE_FAIL, response);
            });
    }

    /**
     * This function grabs the oldest queued / non-submitted input and submits it to the API
     */

    _submitNextQueuedInput() {
        this._queueIsBeingProcessed = true;

        // Get the current oldest queued / non-submitted input. This ensures that
        // the input are processed on the way the user added them
        var queuedInputLocalId = this._getNextQueuedInput();

        // there is no queued item left
        if (queuedInputLocalId === null) {
            this._queueIsBeingProcessed = false;

            return;
        }

        // If there are too many inputs then we reject the rest of the waiting queue
        // and end submitting inputs to the Api. If the user deletes some inputs and then adds
        // fresh inputs it will able to submit them again
        if (this.isInputLimitReached()) {
            this._queueIsBeingProcessed = false;
            this._failQueuedInputs(API_ERROR_LIMITS_TOO_MANY_INPUTS, this._maxNumberOfInputs);

            this.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_TOO_MANY_INPUTS);

            // we want to log this only once per job
            if (!this._sentLogAboutMaxInputs) {
                globalLogger.log('qgMultiUpload processQueuedInputs fail max number of inputs');
                this._sentLogAboutMaxInputs = true;
            }

            return;
        }

        var queuedInput = this._inputList.getInput(queuedInputLocalId);

        var inputTooLarge = queuedInput.getSize() > this._maxFileSize;

        var jobTooLarge = queuedInput.getSize() + this._inputList.getBytesInJob() > this._maxFileSize;

        // TODO jobTooLarge needs to be its own specific message to show better error messages for users!!
        if (inputTooLarge || jobTooLarge) {
            globalLogger.log('qgMultiUpload queue fail', {
                info: 'file too large',
                filename: queuedInput.getName(),
                file_size: queuedInput.getSize(),
                max_file_size: this._maxFileSize,
            });

            var failInputError = new FailInputError(API_ERROR_LIMITS_INPUT_TOO_LARGE);

            failInputError.size = queuedInput.getSize();
            failInputError.maxFileSize = this._maxFileSize;

            this.failInput(queuedInputLocalId, failInputError);

            this.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);

            // if there are no queued inputs amymore then processing is stopped
            if (this._getNextQueuedInput() === null) {
                this._queueIsBeingProcessed = false;

                return;
            }

            this._submitNextQueuedInput();

            return;
        }

        var sendInputToApi = queuedInput.getCallback();

        var _this = this;

        // Hint to a future developer:
        // The .always() thing prevents that more than one input is added to the api
        // at the same time. By using deferreds in this way we ensure that the inputs are
        // added one after the other without overloading the server
        sendInputToApi(queuedInput.getLocalId()).always(function () {
            // if there are no queued inputs amymore then processing is stopped
            if (_this._getNextQueuedInput() === null) {
                _this._queueIsBeingProcessed = false;

                return;
            }

            _this._submitNextQueuedInput();
        });
    }

    /**
     * This sets all queued / non-submitted inputs to fail. This is used on situations when the job
     * creation failed or the max number of inputs is reached
     */

    _getNextQueuedInput() {
        var nextLocalId = null;

        // TODO: fix, this is now an array!
        var _this = this;

        var inputs = this._inputList.getInputs();
        $.each(inputs, function (localId, input) {
            if (input.getStatus() === INPUT_STATUS_UPLOADER_QUEUED && nextLocalId === null) {
                nextLocalId = localId;
            }
        });

        return nextLocalId;
    }

    /**
     * This sets an input to FAILED and dispatches the event
     * @param {number} localId
     * @param {FailInputError} [failInputError]
     */

    failInput(localId, failInputError) {
        var _failInputError = new FailInputError();

        if (testIsObject(failInputError)) {
            assertObjectHasKey(failInputError, 'error');

            _failInputError = failInputError;
        }

        this.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_FAILED);
        this.dispatchInputEvent(INPUT_EVENT_FAIL, localId, _failInputError);
    }

    /**
     * Send delete request to the API
     *
     * @param {number} localId
     */

    deleteInput(localId) {
        let deferred = $.Deferred();

        if (!this._isEnabled) {
            return;
        }

        const input = this.getInput(localId);

        const status = input.getStatus();

        // this is already being deleted so there is nothing to do
        if (status === INPUT_STATUS_UPLOADER_DELETING) {
            return;
        }

        /**
         * Input can only be deleted if it is currently part of the API2
         *
         * @return {boolean}
         */
        var canBeDeleted = function () {
            if (status === INPUT_STATUS_UPLOADER_PASSWORD_MISSING) {
                return true;
            }

            if (status === INPUT_STATUS_UPLOADER_DONE) {
                return true;
            }

            return false;
        };

        // this should never happen
        if (!canBeDeleted()) {
            this.dispatchInputEvent(INPUT_EVENT_UPDATE_STATUS, localId);

            globalLogger.log('qgMultiUpload fail wrong state for being deleted', status);

            return;
        }

        input.setStatus(INPUT_STATUS_UPLOADER_DELETING);

        this.dispatchInputEvent(INPUT_EVENT_DELETING, localId);

        var _this = this;

        this._apiHelper
            .deleteInputAndWait(this._job.getId(), input.getInputId())
            /**
             * @param {object}  response
             * @param {boolean} response.success
             * @param {string}  response.inputId
             * @param {string}  response.jobId
             */
            .done(function () {
                input.setStatus(INPUT_STATUS_UPLOADER_DELETED);
                _this.dispatchInputEvent(INPUT_EVENT_DELETED, localId);

                deferred.resolve();
            })
            .fail(function (error) {
                assertIsApiError(error);
                // on failure set back to previous status
                input.setStatus(status);

                // tell callback receiver to fetch the status of the input
                _this.dispatchInputEvent(INPUT_EVENT_UPDATE_STATUS, localId);

                deferred.reject();
            });

        return deferred;
    }

    /**
     * Send delete request to the API
     *
     * @param {number} localId
     * @param {string} password
     *
     * @return {JQuery.Deferred<string>}
     */
    setPassword(localId, password) {
        let deferred = $.Deferred();

        if (!this._isEnabled) {
            deferred.reject(SET_PASSWORD_ERROR_UNKNOWN_ERROR);

            return deferred;
        }

        const input = this.getInput(localId);

        const status = input.getStatus();

        // password is already being set so there is nothing to do
        if (status === INPUT_STATUS_UPLOADER_SETTING_PASSWORD) {
            deferred.reject(SET_PASSWORD_ERROR_ALREADY_SETTING);

            return deferred;
        }

        // this should never happen
        if (status !== INPUT_STATUS_UPLOADER_PASSWORD_MISSING) {
            globalLogger.log('qgMultiUpload fail wrong state for setting password', status);

            _this.dispatchInputEvent(INPUT_EVENT_UPDATE_STATUS, localId);

            deferred.reject(SET_PASSWORD_ERROR_UNKNOWN_ERROR);

            return deferred;
        }

        input.setStatus(INPUT_STATUS_UPLOADER_SETTING_PASSWORD);

        this.dispatchInputEvent(INPUT_EVENT_SETTING_PASSWORD, localId);

        /** @type {Uploader} */
        const _this = this;

        const jobId = this.getJob().getId();

        const inputId = input.getInputId();

        _this._apiHelper
            .setPassword(jobId, inputId, password)
            /**
             * @param {object} data
             * @param {InputMetadata} data.metadata
             */
            .done(function (data) {
                // it seems that in some cases we receive broken metadata
                if (!testIsObject(data) || !testIsObject(data.metadata)) {
                    globalLogger.log('qgMultiUpload set password - fail - metadata broken', JSON.stringify(data));

                    _this.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_DONE);
                    _this.dispatchInputEvent(INPUT_EVENT_DONE, localId);

                    deferred.reject(SET_PASSWORD_ERROR_UNKNOWN_ERROR);

                    return;
                }

                var passwordMissing = isPasswordMissing(data.metadata);

                if (passwordMissing) {
                    input.setStatus(status);
                    _this.dispatchInputEvent(INPUT_EVENT_UPDATE_STATUS, localId);

                    deferred.reject(SET_PASSWORD_ERROR_WRONG_PASSWORD);

                    return;
                }

                _this.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_DONE);
                _this.dispatchInputEvent(INPUT_EVENT_DONE, localId);

                deferred.resolve();
            })
            .fail(function (errorMessage) {
                globalLogger.log('qgMultiUpload set password fail', errorMessage + 'input id: ' + inputId);

                input.setStatus(status);
                _this.dispatchInputEvent(INPUT_EVENT_UPDATE_STATUS, localId);

                deferred.reject(SET_PASSWORD_ERROR_UNKNOWN_ERROR);
            });

        return deferred;
    }

    /**
     * Cancels an upload
     *
     * @param {number} localId
     */

    cancelFileUpload(localId) {
        if (!this._isEnabled) {
            return;
        }

        var input = this.getInput(localId);

        // if this happens then there is a severe logic error somewhere
        if (input.getType() !== INPUT_TYPE_FILE_UPLOAD) {
            throw new Error('only file uploads can be canceled');
        }

        var status = input.getStatus();

        // this might happen when the use presses cancel when the upload just
        // finished. if it happens very often then there is a logic bug somewhere
        if (status !== INPUT_STATUS_UPLOADER_LOADING) {
            globalLogger.log('qgMultiUpload fileUpload wrong state while canceling');

            return;
        }

        var uploadData = input.getUploadData();

        uploadData.abort();

        // TODO: this needs to be moved to AddFileUpload.prototype._processFileUploadFail() so that
        //       it works correctly even if the UI is not used
        input.setStatus(INPUT_STATUS_UPLOADER_DELETED);

        this.dispatchInputEvent(INPUT_EVENT_UPLOAD_CANCELED, localId);
    }

    /**
     * This sets all queued / non-submitted inputs to fail. This is used on situations when the job
     * creation failed or the max number of inputs is reached
     *
     * @param {string} apiError
     * @param {number} [limitValue]
     */

    _failQueuedInputs(apiError, limitValue) {
        var failInputError = new FailInputError(API_ERROR_UNKNOWN);

        if (testIsString(apiError)) {
            assertIsApiError(apiError);

            failInputError.error = apiError;

            if (apiError === API_ERROR_LIMITS_TOO_MANY_INPUTS && testIsNumber(limitValue)) {
                failInputError.maxNumberOfInputs = limitValue;
            }
        }

        /** @type {Uploader} */
        var _this = this;

        var inputs = this._inputList.getInputs();

        $.each(inputs, function (localId, input) {
            if (input.getStatus() === INPUT_STATUS_UPLOADER_QUEUED) {
                _this.failInput(localId, failInputError);
            }
        });
    }

    /**
     * Returns the number of bytes in active inputs
     *
     * @return {number}
     */

    getBytesInJob() {
        return this._inputList.getBytesInJob();
    }

    /**
     * Returns the number of bytes in input list
     *
     * @return {number}
     */

    getSizeOfAllInputs() {
        return this._inputList.getSizeOfAllInputs();
    }

    /**
     * Checks if max number of input files is reached
     *
     * @returns {boolean}
     */

    isInputLimitReached() {
        return this._inputList.isInputLimitReached();
    }

    /**
     * This periodically checks if an external input has finished downloading / had an error and
     * then updates the status of this input accordingly
     *
     * @param  {number} localId
     */

    waitForRemoteInput(localId) {
        var inputId = this.getInput(localId).getInputId();

        /** @type {Uploader} */
        var _this = this;

        this._job
            .waitForRemoteInput(inputId)
            .done(function (rawInputInfo) {
                assertIsRawInputInfo(rawInputInfo);

                _this.getInput(localId).setSize(rawInputInfo.size);
                _this.getInput(localId).setName(rawInputInfo.filename);

                var passwordMissing = isPasswordMissing(rawInputInfo.metadata);

                if (testIsObject(rawInputInfo)) {
                    let input = _this.getInput(localId);

                    input
                        .setMetadata(rawInputInfo.metadata)
                        .setContentType(rawInputInfo.content_type)
                        .setEngine(rawInputInfo.engine);

                    if (input.getType() === INPUT_TYPE_EXTERNAL_URL) {
                        input.setSource(rawInputInfo.source);
                    }
                }

                if (_this._enablePasswords && passwordMissing) {
                    _this.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_PASSWORD_MISSING);
                    _this.dispatchInputEvent(INPUT_EVENT_PASSWORD_MISSING, localId);
                } else {
                    _this.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_DONE);
                    _this.dispatchInputEvent(INPUT_EVENT_DONE, localId);
                }
            })
            .fail(function (apiError) {
                assertIsApiError(apiError);

                if (apiError === API_ERROR_PAYMENT_REQUIRED) {
                    // TODO: pls investigate, this might better be JOB_TOO_LARGE
                    let failInputError = new FailInputError(API_ERROR_LIMITS_INPUT_TOO_LARGE);

                    globalLogger.log('qgMultiUpload wait for remote input - file too large');

                    _this.failInput(localId, failInputError);

                    _this.dispatchGlobalEvent(GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE);
                }

                var failInputError = new FailInputError(apiError);

                _this.failInput(localId, failInputError);
            });
    }

    /**
     * @param {String} id
     */

    addInputId(id) {
        this._addInputIdHelper.add(id);
    }

    /**
     * @param {String} url
     * @param {String} [engine='auto']
     */

    addExternalUrl(url, engine) {
        if (testIsUndefined(engine)) {
            engine = 'auto';
        }
        this._addExternalUrlHelper.add(url, engine);
    }

    /**
     * @param {Array} urls
     */

    addExternalUrls(urls) {
        assertIsArray(urls);

        var addExternalUrlHelper = this._addExternalUrlHelper;
        urls.forEach(function (url) {
            addExternalUrlHelper.add(url);
        });
    }

    addDropbox() {
        this._addDropboxHelper.add();
    }

    addOnedrive() {
        this._addOnedriveHelper.add();
    }

    /**
     * @param {string} [name]
     * @param {string} [token]
     * @param {string} [fileId]
     * @param {string} [mimeType]
     */

    addGdrive(name, token, fileId, mimeType) {
        this._addGdriveHelper.add(name, token, fileId, mimeType);
    }

    /**
     * If job info comes from sat then it might contain limits which must be applied
     */
    _tryToSetSatLimitsFromJobInfo(jobInfo) {
        var constraints = null;

        try {
            constraints = jobInfo.sat.constraints;

            const jobStore = useJobStore();
            jobStore.setConstraints(constraints);
        } catch (e) {
            // do nothing
        }

        if (constraints === null) {
            return;
        }

        try {
            var maxFileSize = constraints.job.general.max_size;

            if (typeof maxFileSize === 'string') {
                maxFileSize = parseInt(maxFileSize);
            }

            if (testIsNumber(maxFileSize) && !isNaN(maxFileSize) && maxFileSize > 0) {
                this._maxFileSize = maxFileSize;
            }
        } catch (e) {}

        try {
            // If page is configured for single-input-mode then the number-of-input limits are ignored
            // TODO: remove this when the satcore supports feature-sensitive jobs, e.g. jobs with a single input
            if (!this._singleInput) {
                var maxInputs = constraints.job.inputs.max_count;

                if (typeof maxInputs === 'string') {
                    maxInputs = parseInt(maxInputs);
                }

                if (testIsNumber(maxInputs) && !isNaN(maxInputs) && maxInputs > 0) {
                    this._inputList._maxNumberOfInputs = maxInputs;
                }
            }
        } catch (e) {}
    }

    clickFileUpload() {
        this._addFileUploadHelper.click();
    }

    clickDropBox() {
        this._addDropboxHelper.click();
    }

    clickGdrive() {
        this._addGdriveHelper.click();
    }

    clickOnedrive() {
        this._addOnedriveHelper.click();
    }

    /**
     * @param {String} url
     * @param {String} engine
     */
    clickRemoteUrl(url, engine) {
        this._addExternalUrlHelper.click(url, engine);
    }
}

export { Uploader };
