import { assertIsTrue, assertIsUUID4 } from '../../../../lib/test-and-assert/assert-base';
import { testIsRawJobInfo } from '../test';

import { globalLogger } from '../../../../qaamgo/helper/global-logger';

import { getLocationOrigin } from '../../../../qaamgo/helper/location-origin';
import { INPUT_STATUS_API_DOWNLOADING, INPUT_STATUS_API_READY } from '../consts';

/**
 * Helper class which parses the server response of the type 'jobinfo'
 *
 * @param {RawJobInfo} jobInfoRaw The type of the input
 *
 * @constructor
 */
function JobInfoHelper(jobInfoRaw) {
    this._isValid = true;
    this._data = {};
    this._inputs = {};

    if (!testIsRawJobInfo(jobInfoRaw)) {
        this._isValid = false;

        return;
    }

    this._data = jobInfoRaw;
}

/**
 * @return {boolean}
 */
JobInfoHelper.prototype.isValid = function () {
    return this._isValid;
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getId = function () {
    assertIsTrue(this._isValid);

    return this._data.id;
};

/**
 * @return {string|null}
 */
JobInfoHelper.prototype.getConversionId = function () {
    assertIsTrue(this._isValid);

    try {
        return this._data.conversion[0].id;
    } catch (e) {}

    return null;
};

/**
 * @return {string|null}
 */
JobInfoHelper.prototype.getConversionTarget = function () {
    assertIsTrue(this._isValid);

    try {
        return this._data.conversion[0].target;
    } catch (e) {}

    return null;
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getToken = function () {
    assertIsTrue(this._isValid);

    return this._data.token;
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getServer = function () {
    assertIsTrue(this._isValid);

    return this._data.server;
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getUploadUrl = function () {
    assertIsTrue(this._isValid);

    return this.getServer() + '/upload-file/' + this.getId();
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getUploadUrlBase64 = function () {
    assertIsTrue(this._isValid);

    return this.getServer() + '/upload-base64/' + this.getId();
};

/**
 * @return {string}
 */
JobInfoHelper.prototype.getFallbackUploadUrl = function () {
    assertIsTrue(this._isValid);

    // for "https://www3.akokine.com/dl/www3" this returns:
    // ["https://www3.akokine.com/dl/www3", "www3", "akokine.com", "/dl/www3"]
    var parts = this.getServer().match(/^[^:]*:\/\/([^.]+)\.([^\/]+)(\/.*)$/);

    if (parts === null) {
        globalLogger.error('Can not create fallback upload url', this.getServer());

        return this.getUploadUrl();
    }

    var uploadServer = parts[1];
    var path = parts[3];

    var currentUrl = getLocationOrigin();

    return currentUrl + '/upload-redirect/' + uploadServer + path + '/upload-file/' + this.getId();
};

/**
 * @param {string} inputId
 *
 * @return {null|object}
 */
JobInfoHelper.prototype.getInput = function (inputId) {
    assertIsTrue(this._isValid);
    assertIsUUID4(inputId);

    var input = null;

    $.each(this._data.input, function (idx, inp) {
        if (inp.id === inputId) {
            input = inp;
        }
    });

    return input;
};

/**
 *
 * @return {null|object}
 */
JobInfoHelper.prototype.getInputs = function () {
    assertIsTrue(this._isValid);

    return this._data.input;
};

/**
 * @param {string} inputId
 *
 * @return {null|object}
 */
JobInfoHelper.prototype.getInputError = function (inputId) {
    assertIsTrue(this._isValid);
    assertIsUUID4(inputId);

    var error = null;

    $.each(this._data.errors, function (idx, err) {
        if (err.hasOwnProperty('id_source') && err.id_source === inputId) {
            error = err;
        }
    });

    return error;
};

/**
 * @param {string} inputId
 *
 * @return {null|object}
 */
JobInfoHelper.prototype.getInputWarning = function (inputId) {
    assertIsTrue(this._isValid);
    assertIsUUID4(inputId);

    var warning = null;

    $.each(this._data.warnings, function (idx, err) {
        if (err.hasOwnProperty('id_source') && err.id_source === inputId) {
            warning = err;
        }
    });

    return warning;
};

/**
 * TODO T3506 add support for the other api input status: isInputFailed, ...
 *
 * @param {string} inputId
 *
 * @return {boolean}
 */
JobInfoHelper.prototype.isInputReady = function (inputId) {
    assertIsTrue(this._isValid);
    assertIsUUID4(inputId);

    var input = this.getInput(inputId);

    return input.status === INPUT_STATUS_API_READY;
};

/**
 * TODO T3506 add support for the other api input status: isInputFailed, ...
 *
 * @param {string} inputId
 *
 * @return {boolean}
 */
JobInfoHelper.prototype.isInputDownloading = function (inputId) {
    assertIsTrue(this._isValid);
    assertIsUUID4(inputId);

    var input = this.getInput(inputId);

    return input.status === INPUT_STATUS_API_DOWNLOADING;
};

/**
 * @return {RawJobInfo}
 */
JobInfoHelper.prototype.getRawJobInfo = function () {
    assertIsTrue(this._isValid);

    return this._data;
};

export { JobInfoHelper };
