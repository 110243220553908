import { assertIsString } from '../../lib/test-and-assert/assert-base';

/**
 * Tests if a string is a youtube url
 *
 * @param {string} url
 *
 * @return boolean
 */
var isYouTubeUrl = function (url) {
    assertIsString(url);

    // remove all white space
    url = url.replace(/\s/g, '');

    if (url === '') {
        return false;
    }

    var hostname = '';

    try {
        var parser = document.createElement('a');
        parser.href = url;
        hostname = parser.hostname;
        hostname = hostname.toLowerCase();
    } catch (e) {
        return false;
    }

    return (
        hostname.indexOf('youtube.com') !== -1 ||
        hostname.indexOf('youtu.be') !== -1 ||
        hostname.indexOf('googlevideo.com') !== -1
    );
};

export { isYouTubeUrl };
