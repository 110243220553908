import { testIsNonEmptyString, testIsNumber } from '../test-and-assert/test-base';

/**
 * Returns true of jquery is available
 *
 * @return {boolean}
 */
function _hasJqueryAjax() {
    return typeof $ !== 'undefined' && $.hasOwnProperty('ajax');
}

/**
 * Sends data to endpoint based on the presence of jquery
 *
 * @param {string} url
 * @param {boolean} cors
 * @param {object} data
 */
function _send(url, cors, data) {
    try {
        if (_hasJqueryAjax()) {
            _sendWithJquery(url, cors, data);
        }
    } catch (e) {
        // nothing we can do, perhaps do jsonp or some
    }
}

/**
 * Sends data to endpoint based on the presence of jquery
 *
 * @param {string} url
 * @param {boolean} cors
 * @param {object} data
 */
function _sendWithBeacon(url, cors, data) {
    url = url + '?withBeacon=true';

    let headers = {
        type: 'text/plain',
    };

    let blob = new Blob([JSON.stringify({ data: data })], headers);

    try {
        sendWithBeacon(url, blob);
    } catch (e) {
        // do nothing
    }
}

/**
 * Uses jquery to send the data. If sending fails it retries a few times
 *
 * @param {string} url
 * @param {boolean} cors
 * @param {object} data
 */
function _sendWithJquery(url, cors, data) {
    var maxRetries = 2;
    var nRetries = 0;

    var options = {
        url: url,
        method: 'POST',
        data: {
            data: data,
        },
        xhrFields: {
            withCredentials: cors,
        },
    };

    var _sendHelper = function () {
        $.ajax(options).fail(function (jqXHR, textStatus, errorThrown) {
            if (nRetries >= maxRetries) {
                return;
            }

            nRetries += 1;

            // waits are 5, 10, 15, 20, ... seconds
            var waitTime_s = nRetries * 5;

            setTimeout(function () {
                _sendHelper(options);
            }, waitTime_s * 1000);
        });
    };

    _sendHelper();
}

function sendWithBeacon(url, data) {
    navigator.sendBeacon(url, data);
}

/**
 * This takes a JS Error and extracts all the info into an object
 *
 * @param {Error} error
 *
 * @return {Object}
 */
function _parseError(error) {
    if (typeof error === 'undefined') {
        return {};
    }

    if (error === null) {
        return {};
    }

    var data = {};

    if (typeof error.message !== 'undefined') {
        data.exception_message = error.message;
    }

    if (typeof error.name !== 'undefined') {
        data.exception_name = error.name;
    }

    if (typeof error.columnNumber !== 'undefined') {
        data.exception_column = error.columnNumber;
    }

    if (typeof error.lineNumber !== 'undefined') {
        data.exception_line = error.lineNumber;
    }

    if (typeof error.fileName !== 'undefined') {
        data.exception_file = error.fileName;
    }

    if (typeof error.stack !== 'undefined') {
        var stack = 'no valid stack info';

        if (typeof error.stack === 'string') {
            stack = error.stack;
        } else {
            try {
                stack = JSON.stringify(error.stack);
            } catch (e) {
                stack = 'failed to create stack: ' + e.message;
            }
        }

        data.exception_stack = stack;
    }

    return data;
}

/**
 * Takes the log-message, the base data and some extra data creates an object with
 * all the log data from it
 *
 * @param {string}               message
 * @param {object}               baseData
 * @param {string|number|object} [extraData]
 *
 * @return {object}
 */
function _prepareData(message, baseData, extraData) {
    if (typeof message !== 'string') {
        message = 'no valid message in log';
    }

    var data = { message: message };

    try {
        // add all the base data (e.g. jobId, ...)
        Object.assign(data, baseData);

        if (typeof extraData === 'undefined') {
            // do nothing
        } else if (extraData === null) {
            // do nothing
        } else if (typeof extraData === 'string') {
            data.info = extraData;
        } else if (typeof extraData === 'number') {
            data.info = extraData.toString();
        } else if (extraData.isAxiosError) {
            /** @type {AxiosError} */
            const axiosError = extraData;

            const status = axiosError?.response?.status;

            if (testIsNumber(status)) {
                data.http_status = status;
            }

            const responseData = axiosError?.response?.data;

            if (responseData) {
                data.info = JSON.stringify(responseData);
            }
        } else if (extraData instanceof Error) {
            var errorData = _parseError(extraData);
            Object.assign(data, errorData);
        } else if (typeof extraData === 'object') {
            Object.assign(data, extraData);
        } else {
            data.info = JSON.stringify(extraData);
        }

        // always add the current url, this makes debugging much easier!
        try {
            data.page_url = window.location.href;
        } catch (e) {
            // do nothing
        }

        if (typeof data.info === 'string') {
            data.info = _removeUnwantedData(data.info);
        }

        if (testIsNonEmptyString(data.referrer)) {
            // pages like yandex create a referrer which is like 3000 chars long
            // we trim it down to 500 which should be more than enough for everything
            data.referrer = data.referrer.slice(0, 500);
        }
    } catch (e) {
        data = {};

        data.info = 'message: ' + message;
        data.message = 'exception in logger';
        data.exception_message = e.message;

        // Just to make really really sure that we have the job id
        if (baseData.hasOwnProperty('job_id')) {
            data.job_id = baseData.job_id;
        }

        // Just to make really really sure that we have the extra uid
        if (baseData.hasOwnProperty('extra_uid')) {
            data.extra_uid = baseData.extra_uid;
        }
    }

    // TODO: convert http_status, duration_s, ... to number
    // TODO: convert info ... to string

    return data;
}

function _removeUnwantedData(dataInfo) {
    var unwantedKeys = {
        new_user_password: 'new_user_password',
        u_confirm_password: 'u_confirm_password',
        new_owner_password: 'new_owner_password',
        confirm_password: 'confirm_password',
    };

    try {
        dataInfo = JSON.parse(dataInfo);
    } catch (e) {
        return dataInfo;
    }

    if (typeof dataInfo.options !== 'undefined') {
        for (var key in unwantedKeys) {
            if (dataInfo.options.hasOwnProperty(key)) {
                dataInfo.options[key] = '**REMOVED**';
            }
        }
    }

    return JSON.stringify(dataInfo);
}

class Logger {
    constructor(options) {
        if (typeof options !== 'object') {
            throw new Error('Logger needs options object');
        }

        if (typeof options.url !== 'string') {
            throw new Error('Logger needs url');
        }

        this._url = options.url;

        this._cors = options.cors === true;

        this._baseLogData = {};

        this._maxNumberOfLogs = 1000;

        if (typeof options.maxNumberOfLogs === 'number') {
            this._maxNumberOfLogs = options.maxNumberOfLogs;
        }

        this._numberOfLogsSent = 0;

        if (typeof options.extraUid === 'string') {
            this.addLogData('extra_uid', options.extraUid);
        }
    }

    /**
     * This takes a JS Error and extracts all the info into an object
     *
     * @param {Error} error
     *
     * @return {Object}
     */
    parseError(error) {
        return _parseError(error);
    }

    /**
     * This adds data which is sent with every request
     *
     * @param {string} key
     * @param value
     */
    addLogData(key, value) {
        this._baseLogData[key] = value;
    }

    /**
     * Log with a given level
     *
     * @param {string}               message
     * @param {string|number|object} [extra]
     * @param {number}               [level]
     * @param {boolean}              [withBeacon]
     */
    log(message, extra, level, withBeacon) {
        this._numberOfLogsSent++;

        if (!withBeacon) {
            withBeacon = false;
        }

        if (this._numberOfLogsSent > this._maxNumberOfLogs) {
            return;
        }

        try {
            var data = _prepareData(message, this._baseLogData, extra);

            if (typeof level !== 'undefined') {
                data.level = level;
            }

            // the code below is disabled because we do not need it. but i leave it in because
            // it might be useful in some circumstances
            // // TODO T5294 this is only needed for debugging mystery issues
            // const mysteryNavigationData = this.getNavigationData();
            //
            // if (mysteryNavigationData !== '') {
            //     data.navigation = mysteryNavigationData;
            // }

            // this is the 'version' of the log. you can change it from time to time to see
            // if a user uses outdated JS
            data.version = SAT_VERSION ?? 'a36fe2';

            try {
                const referrer = document?.referrer;

                if (testIsNonEmptyString(referrer)) {
                    data.referrer = referrer;
                }
            } catch (e) {}

            let that = this;
            if (withBeacon) {
                // Small hack to make log sending async
                // This is due to incident T1060
                setTimeout(() => {
                    _sendWithBeacon(that._url, that._cors, data);
                }, 0);
            } else {
                // Small hack to make log sending async
                // This is due to incident T1060
                setTimeout(() => {
                    _send(that._url, that._cors, data);
                }, 0);
            }

            if (window.location.href.indexOf('.test') !== -1 || window.location.href.includes('local')) {
                console.log(data.message, extra);
            }
        } catch (e) {
            console.log('error sending log', e.message);
        }
    }

    /**
     * only needed for T5294 to investigate mystery things when user presses BACK button in browser
     * @returns {string}
     */
    getNavigationData() {
        try {
            let navigationData = [];
            const navEntries = performance.getEntriesByType('navigation');

            if (!Array.isArray(navEntries) || navEntries.length === 0) {
                return '';
            }

            navEntries.forEach((navEntry) => {
                if (!navEntry.type) {
                    return '';
                }

                navigationData.push(navEntry.type);
            });

            return JSON.stringify(navigationData);
        } catch (e) {
            return '';
        }
    }

    /**
     * Log with debug level (100)
     *
     * @param {string}               message
     * @param {string|number|object} [extra]
     */
    debug(message, extra) {
        this.log(message, extra, 100);
    }

    /**
     * Log with info level (200)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    info(message, extra) {
        this.log(message, extra, 200);
    }

    /**
     * Log with notice level (250)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    notice(message, extra) {
        this.log(message, extra, 250);
    }

    /**
     * Log with warning level (300)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    warning(message, extra) {
        this.log(message, extra, 300);
    }

    /**
     * Log with error level (400)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    error(message, extra) {
        this.log(message, extra, 400);
    }

    /**
     * Log with critical level (500)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    critical(message, extra) {
        this.log(message, extra, 500);
    }

    /**
     * Log with alert level (550)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    alert(message, extra) {
        this.log(message, extra, 550);
    }

    /**
     * Log with emergency level (600)
     *
     * @param {string} message
     * @param {string|number|object} [extra]
     */
    emergency(message, extra) {
        this.log(message, extra, 600);
    }
}

export { Logger };
