import { testIsGlobalEvent, testIsInputEvent } from './test';

export const GLOBAL_EVENT_JOB_CREATE_START = 'global-event-job-create-start';
export const GLOBAL_EVENT_JOB_CREATE_DONE = 'global-event-job-create-done';
export const GLOBAL_EVENT_JOB_CREATE_FAIL = 'global-event-job-create-fail';
export const GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED = 'global-event-job-create-payment-required';

// Note: these limits are used in:
// ./sat-client/src/Resources/views/auth/register/subscribe.html.twig
// ./sat-client/src/Resources/views/auth/register/register.html.twig
// and also in some PHP controller. If you rename any of the strings below you have to
// search the whole project for usages
/**
 * only X inputs are allowed for free users
 */
export const GLOBAL_EVENT_LIMITS_TOO_MANY_INPUTS = 'global-event-too-many-inputs';

/**
 * an individual input is too large
 */
export const GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE = 'global-event-input-too-large';

/**
 * all inputs combined are too big
 */
export const GLOBAL_EVENT_LIMITS_JOB_TOO_LARGE = 'global-event-job-too-large';

/**
 * youtube urls are forbidden
 */
export const GLOBAL_EVENT_LIMITS_YOUTUBE_FORBIDDEN = 'global-event-youtube-forbidden';

export const NEW_COST_EVENT = 'new-cost-event';

export const GLOBAL_EVENTS = [
    GLOBAL_EVENT_JOB_CREATE_START,
    GLOBAL_EVENT_JOB_CREATE_DONE,
    GLOBAL_EVENT_JOB_CREATE_FAIL,
    GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED,
    GLOBAL_EVENT_LIMITS_TOO_MANY_INPUTS,
    GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE,
    GLOBAL_EVENT_LIMITS_JOB_TOO_LARGE,
    GLOBAL_EVENT_LIMITS_YOUTUBE_FORBIDDEN,
    NEW_COST_EVENT,
];

export const GLOBAL_EVENT_LIMITS = [
    GLOBAL_EVENT_LIMITS_TOO_MANY_INPUTS,
    GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED,
    GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE,
    GLOBAL_EVENT_LIMITS_JOB_TOO_LARGE,
    GLOBAL_EVENT_LIMITS_YOUTUBE_FORBIDDEN,
];

export const INPUT_EVENT_QUEUED = 'input-event-queued';
export const INPUT_EVENT_SUBMITTING = 'input-event-start-submitting'; // 'submitting' is send when a remote input is send to the API. It is not triggered for file uploads
export const INPUT_EVENT_LOADING = 'input-event-start-loading'; // 'loading' is triggered when a) a file starts uploading or b) the waiting for a remote input starts
export const INPUT_EVENT_DONE = 'input-event-done';
export const INPUT_EVENT_FAIL = 'input-event-fail';
export const INPUT_EVENT_SETTING_PASSWORD = 'input-event-setting-password';
export const INPUT_EVENT_PASSWORD_MISSING = 'input-event-password-missing';
export const INPUT_EVENT_UPLOAD_CANCELED = 'input-event-upload-canceled';
export const INPUT_EVENT_DELETING = 'input-event-deleting';
export const INPUT_EVENT_DELETED = 'input-event-deleted';
export const INPUT_EVENT_UPLOAD_PROGRESS = 'input-event-upload-progress';
export const INPUT_EVENT_UPDATE_STATUS = 'input-event-update-status';

export const INPUT_EVENTS = [
    INPUT_EVENT_QUEUED,
    INPUT_EVENT_SUBMITTING,
    INPUT_EVENT_LOADING,
    INPUT_EVENT_DONE,
    INPUT_EVENT_FAIL,
    INPUT_EVENT_SETTING_PASSWORD,
    INPUT_EVENT_PASSWORD_MISSING,
    INPUT_EVENT_UPLOAD_CANCELED,
    INPUT_EVENT_DELETING,
    INPUT_EVENT_DELETED,
    INPUT_EVENT_UPLOAD_PROGRESS,
    INPUT_EVENT_UPDATE_STATUS,
];

export class UploaderEvent {
    /**
     * @param {String} event
     */
    constructor(event) {
        if (!testIsInputEvent(event) && !testIsGlobalEvent(event)) {
            throw new Error('this is not a valid uploader event: ' + event);
        }

        /** @type {String} */
        this.event = event;

        /** @type {?Number} */
        this.localId = null;

        this.data = null;
    }
}

export const UPLOADER_EVENT = 'uploader-event';

export const HIDE_OPTIONS_EVENT = 'hide-options-event';
export const SHOW_OPTIONS_EVENT = 'show-options-event';

export const STOP_AUTO_START_UPLOAD = 'stop-auto-start-upload';
export const AUTO_START_UPLOAD_STOPPED = 'auto-start-upload-stopped';
