import { testIsUndefined } from '../../lib/test-and-assert/test-base';
import { assertIsString } from '../../lib/test-and-assert/assert-base';
import { globalLogger } from './global-logger';

/**
 * HELPER FOR A SINGLE KEY/VALUE
 * HELPER FOR A SINGLE KEY/VALUE
 * HELPER FOR A SINGLE KEY/VALUE
 */

/**
 * This takes a string of the form 'key=value' and checks if it is valid
 *
 * @param {string} keyValueString
 *
 * @return {boolean}
 */
function isValidKeyValueString(keyValueString) {
    try {
        const x = decodeURIComponent(keyValueString);
    } catch (e) {
        return false;
    }

    // must not contain a '&' or '#'
    if (keyValueString.indexOf('&') !== -1 || keyValueString.indexOf('#') !== -1) {
        return false;
    }

    const parts = keyValueString.split('=');

    // in theory there should be only one '=' but in real ife we see people that are adding remote urls like this:
    //
    // https://document.online-convert.com/convert-to-html#remote_url=https://addons.mozilla.org/user-media/addon_icons/306/306836-64.png?modified=1551084107
    //
    // therefore we also allow 1 or 2 of '=' which results in 2 or 3 parts
    const okNumberOfEquals = parts.length === 2 || parts.length === 3;

    if (!okNumberOfEquals) {
        return false;
    }

    const key = parts[0].trim();
    const value = parts[1];

    // if we remove any whitespace from key then it must not be empty
    if (key.length === 0) {
        return false;
    }

    try {
        const x = decodeURIComponent(value);
    } catch (e) {
        return false;
    }

    return true;
}

/**
 * This takes a string of the form 'key=value' and returns an array with 2 entries for the
 * key and the value. If it is not a valid string then it returns null
 *
 * @param {String} keyValue
 *
 * @return {?string[]}
 */
function parseKeyValueString(keyValue) {
    // the next two lines are for cases like remote_url=https://host.com/image?width=200
    // we only want to split at the first occurence of an equal sign
    // 'key' will contain the sub string in front of the first equal sign and value will contain the rest of the string
    // behing the first equal sign
    let [key, ...values] = keyValue.split('=');
    let value = values.join('=');

    key = key.trim().toLowerCase();

    if (key.length === 0) {
        return null;
    }

    try {
        value = decodeURIComponent(value);
    } catch (e) {
        return null;
    }

    return [key, value];
}

/**
 * HELPER FOR A PARAMS STRING
 * HELPER FOR A PARAMS STRING
 * HELPER FOR A PARAMS STRING
 */

/**
 * This takes a string of the form 'key1=value1&key2=value2&...' and checks if
 * it is valid
 *
 * @param {string} keyValuesString
 *
 * @return {boolean}
 */
function isValidParamsString(keyValuesString) {
    let valid = true;

    const parts = keyValuesString.split('&');

    parts.forEach(function (value, index) {
        if (!isValidKeyValueString(value)) {
            valid = false;
        }
    });

    return valid;
}

/**
 * @param {String} keyValues
 *
 * @return {?Object}
 */
function parseParamsString(keyValues) {
    if (!isValidParamsString(keyValues)) {
        globalLogger.log('invalid fragment or query in url', JSON.stringify(keyValues));

        return null;
    }

    let data = {};

    const parts = keyValues.split('&');

    let foundValidData = false;

    parts.forEach(function (value, idx) {
        const d = parseKeyValueString(value);

        if (data !== null) {
            foundValidData = true;

            const key = d[0];
            const value = d[1];

            data[key] = value;
        }
    });

    if (foundValidData) {
        return data;
    }

    return null;
}

/**
 * HELPER TO GET QUERY/FRAGMENT STRING FROM URL
 * HELPER TO GET QUERY/FRAGMENT STRING FROM URL
 * HELPER TO GET QUERY/FRAGMENT STRING FROM URL
 * HELPER TO GET QUERY/FRAGMENT STRING FROM URL
 */

/**
 * Returns the query part of an url. If no url is given it uses the current url
 *
 * @param {string} [url]
 *
 * @return {string|null}
 */
function getQueryStringFromUrl(url) {
    let search;

    if (testIsUndefined(url)) {
        search = location.search.trim();
    } else {
        let anchor = document.createElement('a');

        anchor.href = url;

        search = anchor.search.trim();
    }

    if (search.charAt(0) !== '?') {
        return null;
    }

    return search.substring(1);
}

/**
 * Returns the fragment part of an url. If no url is given it uses the current url
 *
 * @param {string} [url]
 *
 * @return {string|null}
 */
function getFragmentStringFromUrl(url) {
    let fragment;

    if (testIsUndefined(url)) {
        fragment = location.hash.trim();
    } else {
        let anchor = document.createElement('a');

        anchor.href = url;

        fragment = anchor.hash.trim();
    }

    if (fragment.charAt(0) !== '#') {
        return null;
    }

    return fragment.substring(1);
}

/**
 * OTHER
 * OTHER
 * OTHER
 * OTHER
 */

/**
 * Returns an simple key/value object with the url-decoded query data of the current url
 *
 * -> keys are lowercased and trimmed
 * -> if the same key appears several times then the previous values will be overwritten
 * -> if there is no valid query data then it returns just an empty object
 *
 * @return {Object}
 */
function getQueryAsObject(url) {
    const queryString = getQueryStringFromUrl(url);

    if (queryString === null) {
        return {};
    }

    const d = parseParamsString(queryString);

    if (d === null) {
        return {};
    }

    return d;
}

/**
 * Returns an simple key/value object with the url-decoded query data of the current url
 *
 * -> keys are lowercased and trimmed
 * -> if the same key appears several times then the previous values will be overwritten
 * -> if there is no valid query data then it returns just an empty object
 *
 * @return {Object}
 */
function getFragmentAsObject(url) {
    const fragmentString = getFragmentStringFromUrl(url);

    if (fragmentString === null) {
        return {};
    }

    const data = parseParamsString(fragmentString);

    if (data === null) {
        return {};
    }

    return data;
}

/**
 * Returns the query data of the current page as an object. If there is no (valid) query
 * data then it returns an empty object
 *
 * @return {Object}
 */
function getCurrentQueryData() {
    return getQueryAsObject();
}

/**
 * Returns the fragment data of the current page as an object. If there is no (valid) fragment
 * data then it returns an empty object
 *
 * @return {Object}
 */
function getCurrentFragmentData() {
    return getFragmentAsObject();
}

/**
 * Returns the value of the key from the current url. if it does not exist we return null
 *
 * @param {string} key
 *
 * @return {null|string}
 */
function getCurrentQueryValue(key) {
    const data = getCurrentQueryData();

    key = key.trim().toLowerCase();

    if (data.hasOwnProperty(key)) {
        return data[key];
    }

    return null;
}

/**
 * Returns the value of the key from the current url. if it does not exist we return null
 *
 * @param {string} key
 *
 * @return {null|string}
 */
function getCurrentFragmentValue(key) {
    const data = getCurrentFragmentData();

    key = key.trim().toLowerCase();

    if (data.hasOwnProperty(key)) {
        return data[key];
    }

    return null;
}

/**
 * Returns the value of the key from the current url. if it does not exist we return null
 *
 * @param {string} key
 *
 * @return {null|string}
 */
function getValueFromCurrentUrl(key) {
    assertIsString(key);

    const _key = key.trim().toLowerCase();

    // first we try fragments because that's out main source of info...
    const fragmentData = getCurrentFragmentData();

    if (fragmentData.hasOwnProperty(_key)) {
        return fragmentData[_key];
    }

    // ... and this is just for backwards compatibility
    const queryData = getCurrentQueryData();

    if (queryData.hasOwnProperty(_key)) {
        return queryData[_key];
    }

    return null;
}

export { getCurrentQueryData };
export { getCurrentQueryValue };
export { getCurrentFragmentData };
export { getCurrentFragmentValue };
export { getValueFromCurrentUrl };
export { getFragmentStringFromUrl };
