import { testIsNonEmptyString, testIsNumber } from '../../../../lib/test-and-assert/test-base';

import { globalLogger } from '../../../../qaamgo/helper/global-logger';

import { isUploadFallbackUrl } from './file-upload';

import { sendToAnalytics } from '../../../../qaamgo/helper/influx';

/**
 * @param {JqueryFileUploadData} data
 */
function logRetryInfo(data) {
    var ajax_status = 0;
    var ajax_error = '';
    var info = '';

    try {
        // check if there is a change in the file name/size
        logChangeFileData(data);

        info = 'current retry: ' + data.pgRetryHandler.getCurrentRetry();

        info += ' | uploaded bytes: ' + data.uploadedBytes;
        info += ' | file size: ' + data.files[0].size;
        info += ' | file name: ' + data.files[0].name;
        info += ' | bitrate: ' + data.pgCurrentBitrate;
        info += ' | chunk size: ' + data.pgCurrentChunkSize;
        info += ' | speed modifier: ' + data.pgCurrentSpeedModifier;

        try {
            info += ' | file last modified: ' + data.files[0].lastModified;
        } catch (e) {
            // do nothing
        }

        if (!data.hasOwnProperty('jqXHR')) {
            info += ' | jqXHR missing!';
        }

        // HTTP STATUS
        try {
            if (data.jqXHR.hasOwnProperty('status')) {
                info += ' | status code: ' + data.jqXHR.status;
            }
        } catch (e) {
            // do nothing ...
        }

        // RESPONSE TEXT
        try {
            if (data.jqXHR.hasOwnProperty('responseText')) {
                if (typeof data.jqXHR.responseText === 'string') {
                    info += ' | response text: ' + data.jqXHR.responseText.substr(0, 512);
                } else {
                    info += ' | response text: NOT A STRING ' + JSON.stringify(data.jqXHR.responseText);
                }
            }
        } catch (e) {
            // do nothing ...
        }

        // RESPONSE JSON
        try {
            if (data.jqXHR.hasOwnProperty('responseJSON')) {
                if (typeof data.jqXHR.responseJSON === 'object') {
                    info += ' | response json: ' + JSON.stringify(data.jqXHR.responseJSON);
                } else {
                    info += ' | response json: NOT AN OBJECT ' + JSON.stringify(data.jqXHR.responseJSON);
                }
            }
        } catch (e) {
            // do nothing ...
        }

        // URL
        if (data.hasOwnProperty('url')) {
            if (typeof data.url !== 'string') {
                info += ' | url: URL NOT A STRING';
            } else if (data.url === '') {
                info += ' | url: EMPTY URL';
            } else {
                info += ' | url: "' + data.url + '"';
            }
        } else {
            info += ' | url: NO URL';
        }

        // HEADERS
        if (data.hasOwnProperty('headers')) {
            info += ' | headers: ' + JSON.stringify(data.headers);
        } else {
            info += ' | headers: NO HEADERS';
        }

        try {
            if (isUploadFallbackUrl(data.url)) {
                info += ' | use upload fallback: true';
            }
        } catch (e) {
            // do nothing..
        }

        ajax_status = data.textStatus;
        ajax_error = data.errorThrown;
    } catch (e) {
        info = 'exception during logging';

        if (e.hasOwnProperty('message')) {
            info += ': ' + e.message;
        }
    }

    globalLogger.log('qgMultiUpload fileUpload retry', {
        info: info,
        ajax_status: ajax_status,
        ajax_error: ajax_error,
    });
}

/**
 * @param {JqueryFileUploadData} data
 */
function logFailInfo(data) {
    var ajax_status = 0;
    var ajax_error = '';
    var info = '';

    try {
        // check if there is a change in the file name/size
        logChangeFileData(data);

        info = 'retries: ' + data.pgRetryHandler.getCurrentRetry();

        info += ' | uploaded bytes: ' + data.uploadedBytes;

        try {
            info += ' | file size: ' + data.files[0].size;
        } catch (e) {
            // do nothing..
        }

        try {
            info += ' | file last modified: ' + data.files[0].lastModified;
        } catch (e) {
            // do nothing..
        }

        try {
            info += ' | server response: ' + data.jqXHR.responseText.substr(0, 250);
        } catch (e) {
            // do nothing..
        }

        try {
            info += ' | server error message: ' + data.jqXHR.responseJSON.error.substr(0, 250);
        } catch (e) {
            // do nothing..
        }

        try {
            info += ' | status code: ' + data.jqXHR.status;
        } catch (e) {
            // do nothing..
        }

        try {
            if (isUploadFallbackUrl(data.url)) {
                info += ' | use upload fallback: true';

                // write a log just for stats
                globalLogger.log('qgMultiUpload fileUpload with fallback fail');
            }
        } catch (e) {
            // do nothing..
        }

        ajax_status = data.textStatus;
        ajax_error = data.errorThrown;
    } catch (e) {
        info = 'exception during logging';

        if (e.hasOwnProperty('message')) {
            info += ': ' + e.message;
        }
    }

    globalLogger.log('qgMultiUpload fileUpload fail', {
        info: info,
        ajax_status: ajax_status,
        ajax_error: ajax_error,
    });
}

/**
 * @param {JqueryFileUploadData} data
 */
function logChangeFileData(data) {
    var currentFilename = null;
    var currentFilesize = null;
    var currentLastModified = null;

    try {
        if (testIsNonEmptyString(data.files[0].name)) {
            currentFilename = data.files[0].name;
        }
    } catch (e) {}

    try {
        if (testIsNumber(data.files[0].size)) {
            currentFilesize = data.files[0].size;
        }
    } catch (e) {}

    try {
        if (testIsNumber(data.files[0].lastModified)) {
            currentLastModified = data.files[0].lastModified;
        }
    } catch (e) {}

    if (currentFilename !== data.pgInitialName || currentFilesize !== data.pgInitialSize) {
        var info =
            'name initial: ' +
            data.pgInitialName +
            ' name now: ' +
            currentFilename +
            ' size initial: ' +
            data.pgInitialSize +
            ' size now: ' +
            currentFilesize +
            ' last modified now: ' +
            currentLastModified;

        globalLogger.log('qgMultiUpload fileUpload info - file data changed', {
            info: info,
        });
    }
}

/**
 */
function logChunk(data) {
    sendToAnalytics('chunkinfo', 0, data);
}

export { logChangeFileData };
export { logFailInfo };
export { logRetryInfo };
export { logChunk };
