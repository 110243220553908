/**
 * @param {number} [maxRetries]
 * @constructor
 */
function RetryHandler(maxRetries) {
    this._maxRetries = 20;

    this._maxTimeOut_s = 10;

    if (typeof maxRetries === 'number') {
        this._maxRetries = maxRetries;
    }

    this._currentRetry = 0;

    this._sucessfulChunks = 0;
    this._failedChunks = 0;
}

/**
 * @return {number}
 */
RetryHandler.prototype.getCurrentRetry = function () {
    return this._currentRetry;
};

/**
 * @return {number}
 */
RetryHandler.prototype.getSuccessfullChunks = function () {
    return this._sucessfulChunks;
};

/**
 * @return {number}
 */
RetryHandler.prototype.getFailedChunks = function () {
    return this._failedChunks;
};

/**
 * This should be called every time a chunk upload was successful
 */
RetryHandler.prototype.handleChunkSuccess = function () {
    this._currentRetry = 0;
    this._sucessfulChunks++;
};

/**
 * This should be called every time a chunk upload failed
 */
RetryHandler.prototype.handleChunkFail = function () {
    this._currentRetry++;
    this._failedChunks++;
};

/**
 * @return {boolean}
 */
RetryHandler.prototype.isAllowedToRetry = function () {
    return this._currentRetry <= this._maxRetries;
};

/**
 * Calculates the waiting time in seconds for the next chunk
 *
 * @return {number}
 */
RetryHandler.prototype.getTimeOutForNextChunk_s = function () {
    var timeOut_s = 2 * this._currentRetry;

    if (timeOut_s > this._maxTimeOut_s) {
        return this._maxTimeOut_s;
    }

    return timeOut_s;
};

/**
 * Calculates the waiting time in milliseconds for the next chunk
 *
 * @return {number}
 */
RetryHandler.prototype.getTimeOutForNextChunk_ms = function () {
    return this.getTimeOutForNextChunk_s() * 1000;
};

export { RetryHandler };
