/**
 * Returns object with key/values of each cookie
 *
 * WARNING: keys are translated to lowercase!!!
 *
 * @return {Object}
 */
import { satGlobals } from '../sat/helper/sat-globals';
import { assertIsNonEmptyString } from '../../lib/test-and-assert/assert-base';
import { testIsNonEmptyString } from '../../lib/test-and-assert/test-base';

function getCookies() {
    const rawCookieString = document.cookie;

    let cookies = {};

    const parts = rawCookieString.split(';');

    parts.forEach(function (singleRawCookie) {
        const p = singleRawCookie.split('=');

        if (p.length === 2) {
            const key = p[0].trim().toLowerCase();
            const value = p[1].trim();

            cookies[key] = value;
        }
    });

    return cookies;
}

/**
 * @param {String} name
 *
 * @return
 */
function getCookieValue(name) {
    if (!isCookieSet(name)) {
        return null;
    }

    const cookies = getCookies();

    const _name = name.toLowerCase().trim();

    return cookies[_name];
}

/**
 * @param {String} cookieName
 *
 * @return {boolean}
 */
function isCookieSet(cookieName) {
    const cookies = getCookies();

    const _name = cookieName.toLowerCase().trim();

    return cookies.hasOwnProperty(_name);
}

/**
 * @param {String} name
 * @param {any} value
 * @param {Number} ttl
 * @param {String} [path]
 * @param {String} [cookieDomain]
 */
function setCookie(name, value, ttl, path, cookieDomain) {
    assertIsNonEmptyString(name);

    if (!testIsNonEmptyString(cookieDomain)) {
        cookieDomain = satGlobals.getCookieDomain();
    }

    if (!testIsNonEmptyString(path)) {
        path = '/';
    }

    let cookieStr = `${name}=${value}; max-age=${ttl}; path=${path}; secure;`;

    if (cookieDomain) {
        cookieStr += `domain=${cookieDomain};`;
    }

    document.cookie = cookieStr;
}

export { setCookie };

const cookieHelper = {
    isCookieSet: isCookieSet,
    getCookieValue: getCookieValue,
    setCookie: setCookie
};

export { isCookieSet, getCookieValue };
export { cookieHelper };
