import {
    testIsFileUploadConfig,
    testIsGlobalEvent,
    testIsInputEvent,
    testIsInputStatusApi,
    testIsInputStatusUploader,
    testIsInputType,
    testIsRawInputInfo,
    testIsRawJobInfo,
    testIsUploadedFileInfoObject,
    testIsUploaderEvent,
} from './test';

import { getAssertMessage } from '../../../lib/test-and-assert/get-assert-message';

/**
 * @param {RawJobInfo} rawJobInfo
 */
var assertIsRawJobInfo = function (rawJobInfo) {
    if (!testIsRawJobInfo(rawJobInfo)) {
        throw new Error(getAssertMessage('invalid raw job object'));
    }
};

/**
 * @param {RawInputInfo} rawInputInfo
 */
var assertIsRawInputInfo = function (rawInputInfo) {
    if (!testIsRawInputInfo(rawInputInfo)) {
        throw new Error(getAssertMessage('invalid raw input info'));
    }
};

/**
 * @param {UploadedFileInfo} uploadedFileInfo
 */
var assertIsUploadedFileInfoObject = function (uploadedFileInfo) {
    if (!testIsUploadedFileInfoObject(uploadedFileInfo)) {
        throw new Error(getAssertMessage('not uploadedFileInfo object'));
    }
};

/**
 * @param {String} status
 */
var assertIsInputStatusUploader = function (status) {
    if (!testIsInputStatusUploader(status)) {
        throw new Error(getAssertMessage('invalid input status (uploader)'));
    }
};

/**
 * @param {String} status
 */
var assertIsInputStatusApi = function (status) {
    if (!testIsInputStatusApi(status)) {
        throw new Error(getAssertMessage('invalid input status (api)'));
    }
};

/**
 * @param {String} type
 */
var assertIsInputType = function (type) {
    if (!testIsInputType(type)) {
        throw new Error(getAssertMessage('invalid input type'));
    }
};

/**
 * @param {String} event
 */
var assertIsInputEvent = function (event) {
    if (!testIsInputEvent(event)) {
        throw new Error(getAssertMessage('invalid input event'));
    }
};

/**
 * @param {String} event
 */
var assertIsGlobalEvent = function (event) {
    if (!testIsGlobalEvent(event)) {
        throw new Error(getAssertMessage('invalid global event'));
    }
};

/**
 * @param {object} config
 */
var assertIsFileUploadConfig = function (config) {
    if (!testIsFileUploadConfig(config)) {
        throw new Error(getAssertMessage('invalid fileuploadconfig'));
    }
};

/**
 * @param {UploaderEvent} uploaderEvent
 */
var assertIsUploaderEvent = function (uploaderEvent) {
    if (!testIsUploaderEvent(uploaderEvent)) {
        throw new Error(getAssertMessage('invalid uploaderevent'));
    }
};

export { assertIsRawJobInfo };
export { assertIsRawInputInfo };
export { assertIsUploadedFileInfoObject };
export { assertIsInputStatusUploader };
export { assertIsInputStatusApi };
export { assertIsInputEvent };
export { assertIsInputType };
export { assertIsGlobalEvent };
export { assertIsFileUploadConfig };
export { assertIsUploaderEvent };
