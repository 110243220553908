import { globalLogger } from './global-logger';
import { testIsFalseish } from '../../lib/test-and-assert/test-base';
import { satGlobals } from '../sat/helper/sat-globals';

function init() {
    let isPageVersionActiveFalse = $('#pageVersionActivatingStatus').val();
    let isPagePublishedFalse = $('#pagePublishedFalse').val();
    let currentUrl = window.location.href;

    if (satGlobals.getEnv() === 'prod') {
        globalLogger.log('Page is visible even though it should not be!');
    }

    if (!testIsFalseish(isPageVersionActiveFalse)) {
        $('.inactive-page-version-toast').toast('show');
    }

    if (!testIsFalseish(isPagePublishedFalse)) {
        $('.unpublished-page-toast').toast('show');
    }
}

init();

var unpublishedPage = {
    init: init,
};

export { unpublishedPage };
