import { usePageStore } from '../store/page-store';
import { GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED, INPUT_EVENT_FAIL, UPLOADER_EVENT } from '../uploader/core/event';
import { testIsInputEvent } from '../uploader/core/test';
import { testIsArray, testIsEmptyArray, testIsNumber, testIsString } from '../../lib/test-and-assert/test-base';
import { LIMIT_BATCH_IS_PREMIUM, LIMIT_INPUT_TOO_LARGE, LIMIT_TOO_MANY_INPUTS, LIMIT_UNKNOWN } from './limits';
import { LimitData } from './new/limit-data';
import { globalLogger } from '../../qaamgo/helper/global-logger';
import {
    API_ERROR_LIMITS_INPUT_TOO_LARGE,
    API_ERROR_LIMITS_TOO_MANY_INPUTS,
    API_ERROR_LIMITS_YOUTUBE_FORBIDDEN,
} from '../api/consts';
import { testIsRawConstraintStatus } from './new/test';
import { assertIsArray } from '../../lib/test-and-assert/assert-base';
import { translate } from '../../qaamgo/helper/translate';
import { pageNotification } from '../../qaamgo/helper/notify';
import { eventBus } from '../../lib/event-bus/event-bus';
import { watch } from 'vue';
import { userManager } from '../user/user-manager';

let pageStore = null;

function initUploaderEvents() {
    // this is for can-not-create-job
    eventBus.listen(UPLOADER_EVENT, (/**UploaderEvent*/ uploaderEvent) => {
        if (uploaderEvent.event !== GLOBAL_EVENT_JOB_CREATE_PAYMENT_REQUIRED) {
            return;
        }

        if (testIsArray(uploaderEvent.data)) {
            const response = uploaderEvent.data;

            let limitDataList = getLimitDataListFromPaymentRequiredResponse(response);

            pageStore.limits.addLimitsUploaderEvent(limitDataList);
        }
    });

    let pageNotificationShown = false;

    // this is for limits from inputs
    eventBus.listen(UPLOADER_EVENT, (/**UploaderEvent*/ uploaderEvent) => {
        if (!testIsInputEvent(uploaderEvent.event)) {
            return;
        }

        if (uploaderEvent.event !== INPUT_EVENT_FAIL) {
            return;
        }

        const isSingleFilePage = global.pageConfig?.singleFile === true;

        /** @type {FailInputError} */
        const failInputError = uploaderEvent.data;

        // this is all stuff like API_ERROR_LIMITS_XYZ
        const inputError = failInputError.error;

        // if the user ads too many files on landing page of pdf2go editor then we do not want the payment dialog
        if (isSingleFilePage && inputError === API_ERROR_LIMITS_TOO_MANY_INPUTS) {
            return;
        }

        /** @type {?LimitData} */
        let limitData = null;

        const jobConstraints = pageStore.job.getConstraints;

        if (inputError === API_ERROR_LIMITS_YOUTUBE_FORBIDDEN) {
            const message = translate('Unfortunately, we are not authorized to download videos from this platform.');
            globalLogger.log('external url helper - add fail - youtube');

            setTimeout(function () {
                pageNotificationShown = false;
            }, 5000);

            if (!pageNotificationShown) {
                pageNotification.warning(message);
                pageNotificationShown = true;
            }
        }

        if (inputError === API_ERROR_LIMITS_TOO_MANY_INPUTS) {
            limitData = new LimitData(LIMIT_TOO_MANY_INPUTS);

            const maxInputs = Number.parseInt(jobConstraints?.job?.inputs?.max_count);
            if (testIsNumber(maxInputs)) {
                if (maxInputs === 1) {
                    limitData.type = LIMIT_BATCH_IS_PREMIUM;
                }

                limitData.maxValue = maxInputs;
            }
        }

        // despite its name this happens if 1) input is too large and also if 2) job is too large
        // TODO: we also need a dedicated job-is-too-large message
        if (inputError === API_ERROR_LIMITS_INPUT_TOO_LARGE) {
            limitData = new LimitData(LIMIT_INPUT_TOO_LARGE);

            limitData.currentValue = failInputError.size;

            const maxSize = Number.parseInt(jobConstraints?.job?.inputs?.max_size);
            if (testIsNumber(maxSize)) {
                limitData.maxValue = maxSize;
            }
        }

        if (limitData !== null) {
            const limitList = [];

            limitList.push(limitData);

            pageStore.limits.addLimitsUploaderEvent(limitList);
        }
    });
}

async function setLimitsFromUser() {
    let user = await userManager.getUser();
    const meData = user.getRawMeData();

    let limitsList = [];

    if (testIsArray(meData.constraints_status)) {
        meData.constraints_status.forEach((/** RawConstraintStatus */ constraintStatus) => {
            limitsList.push(LimitData.createFromConstraintStatus(constraintStatus));
        });
    }

    pageStore.limits.addLimitsPageLoad(limitsList);
}

/**
 * @param {RawConstraintStatus[]} response
 *
 * @returns {Array}
 */
function getLimitDataListFromPaymentRequiredResponse(response) {
    assertIsArray(response);

    let limitDataList = [];

    if (testIsArray(response)) {
        response.forEach((rawConstraintStatus) => {
            if (!testIsRawConstraintStatus(rawConstraintStatus)) {
                return;
            }

            limitDataList.push(LimitData.createFromConstraintStatus(rawConstraintStatus));
        });
    }

    if (testIsEmptyArray(limitDataList)) {
        limitDataList.push(new LimitData(LIMIT_UNKNOWN));
    }

    return limitDataList;
}

/**
 * @param {any|Array} [paymentRequiredResponse]
 */
function limitReachedStartConversion(paymentRequiredResponse) {
    let limitDataList = getLimitDataListFromPaymentRequiredResponse(paymentRequiredResponse);

    pageStore.limits.addLimitsUploaderEvent(limitDataList);
}

export { limitReachedStartConversion };

function limitReachedUnknown() {
    const limitData = new LimitData(LIMIT_UNKNOWN);

    pageStore.limits.addLimitsUploaderEvent([limitData]);
}

export { limitReachedUnknown };

let userCookieWatcherStarted = false;

function initUserCookieWatcher() {
    if (userCookieWatcherStarted) {
        return;
    }
    userCookieWatcherStarted = true;

    watch(
        () => pageStore.user.getUser,
        (/** User */ user) => {
            let role = 'unregistered';

            if (user.isRegistered()) {
                role = 'registered';
            }

            if (user.isPremium()) {
                role = 'premium';
            }

            // 24 hours
            const maxAge = 60 * 60 * 24;

            let cookieString = 'qgrole=' + role + '; max-age=' + maxAge + '; secure; path=/;';

            if (testIsString(window.sat.cookieDomain)) {
                cookieString += 'domain=' + window.sat.cookieDomain + ';';
            }

            document.cookie = cookieString;
        }
    );
}

let initialized = false;

function initLimitSystem() {
    if (initialized) {
        globalLogger.log('limits system initialized multiple times');
        return;
    }

    initialized = true;
    pageStore = usePageStore();

    initUserCookieWatcher();
    initUploaderEvents();
    setLimitsFromUser();
}

export { initLimitSystem };
